import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  APPROVAL_STATUS_PENDING,
  APPROVAL_STATUS_REJECT,
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  LINEHAUL_PARTNER_DRIVER_USER_TYPE,
  PRFLCOMP,
  PRFLDR,
} from "../../../ConfigConstants/ConfigConstants";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { getPersonalId, getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  approvalHistory,
  getFileName,
  toBase64,
} from "../../Helper/CommonMethods";

import { DATE_FORMAT_CLIENT, SERVER_ERR_MSG } from "../../Helper/Constants";
import { DownloadFile, GetBase64Img } from "../../Helper/FileStoreService";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import ApprovalLogList from "../ApprovalLogList";
import { initValPartnerPrf } from "./initValPartnerPrf";

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgI: "",
};

let paramUserType = 0;
let paramPrflId = 0;

export default function PartnerDetails() {
  const { state } = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [partnerPrflDetails, setPartnerPrflDetails] =
    useState(initValPartnerPrf);
  const LOG_TYPE_APPROVE = "APPROVE";
  const LOG_TYPE_REJECT = "REJECT";
  const [show, setShow] = useState(false);
  const [imgData, setImgData] = useState(
    JSON.parse(JSON.stringify(initImgData))
  );
  const callbackHinstory = () => {
    navigate(-1);
  };
  const [isDisabled, setDisabled] = useState({});
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const navigate = useNavigate();
  const [driverLicenseData, setDriverLicenseData] = useState({});
  const [isDriverPrfl, setDriverPrfl] = useState(false);
  const [searchParams] = useSearchParams();
  const [driverICData, setDriverICData] = useState({});

  const {
    reset,
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: partnerPrflDetails,
  });
  const { fields: vhclList } = useFieldArray({
    control,
    name: "vehicleList",
  });

  useEffect(() => {
    const userPerm = JSON.parse(getUserInfo("userPermissions"));
    const partnerFeat = userPerm?.filter(
      (feat) => feat.featureCode === PRFLDR || feat.featureCode === PRFLCOMP
    );
    const drFeat = partnerFeat?.find((feat) => feat.featureCode === PRFLDR);
    const compFeat = partnerFeat?.find((feat) => feat.featureCode === PRFLCOMP);
    paramUserType = state?.userType || Number(searchParams.get("userType"));
    paramPrflId = state?.profileId || Number(searchParams.get("profileId"));
    setDriverPrfl(
      parseInt(paramUserType) === parseInt(LINEHAUL_PARTNER_DRIVER_USER_TYPE) &&
        drFeat?.allowedRead
    );
    if (
      (parseInt(paramUserType) ===
        parseInt(LINEHAUL_PARTNER_DRIVER_USER_TYPE) &&
        drFeat?.allowedRead) ||
      (parseInt(paramUserType) ===
        parseInt(LINEHAUL_PARTNER_COMPANY_USER_TYPE) &&
        compFeat?.allowedRead)
    ) {
      getPartnersDetails(paramPrflId, drFeat);
    } else {
      navigate("/unauthorized");
    }
  }, []);

  const getPartnersDetails = async (profileId, argIsDriverPrfl) => {
    let roles = JSON.parse(getUserInfo("userRoles"));
    const userType = paramUserType;
    let uri = `GetPartnerProfile?profileId=${profileId}&roleId=${roles[0].roleId}&userType=${userType}`;
    setShow(true);
    let data = await CallApiGet(uri);

    setShow(false);
    if (data.respCode === 200 || data.respCode === 0) {
      setPartnerPrflDetails(data.respData);
      let partnerPrflDetails = data.respData;
      reset(data.respData);
      if (!!argIsDriverPrfl && argIsDriverPrfl?.allowedRead) {
        setDriverLicenseData(
          partnerPrflDetails && partnerPrflDetails.userInfoList
            ? partnerPrflDetails.userInfoList.filter(
                (info) => info.infoName === "DRIVER_LICENSE"
              )[0]
            : {}
        );
        setDriverICData(
          partnerPrflDetails && partnerPrflDetails.userInfoList
            ? partnerPrflDetails.userInfoList.filter(
                (info) => info.infoName === "USER_IC"
              )[0]
            : {}
        );
        downloadVehicleimage(partnerPrflDetails.vehicleList[0]);
      }
    } else if (data.respCode === 500) {
      onFailure(SERVER_ERR_MSG);
    } else {
      onFailure(data.respMessage);
    }
  };

  const downloadVehicleimage = async (vehicle) => {
    GetBase64Img(vehicle?.vhclImgF?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgF: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgB?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgB: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgL?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgL: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgR?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgR: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgI?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgI: resp,
      }));
    });
  };

  const onSubmit = async (data, requestType) => {
    if (requestType !== LOG_TYPE_APPROVE && data.remarks === "") {
      onFailure(
        "Please provide a valid comment as you are rejecting the partner"
      );
      return;
    } else if (requestType === LOG_TYPE_APPROVE && data.remarks === "") {
      data.remarks = "Approved";
    }
    const model = {
      approvalUserId: paramPrflId, // data.userId,
      logText: data.remarks,
      logAttachment: {
        photo: "",
        photoFileType: "",
        photoBucketUrl: "",
      },
      personalId: getPersonalId(),
      logType: requestType,
      isCustomer: false,
      approvalUserTypeId: partnerPrflDetails.approvalUserTypeId,
    };
    setShow(true);
    let approvalResp = await CallApiPost("log-approval", model);
    setShow(false);
    if (approvalResp.respCode === 200 || approvalResp.respCode === 0) {
      switch (requestType) {
        case "APPROVE":
          onSuccess("APPROVED", "");
          break;
        case "REJECT":
          onSuccess("REJECTED", "");
          break;
        default:
          break;
      }
    } else if(approvalResp.respCode === 406) {
      onFailure(approvalResp.respMessage);
    } else if (approvalResp.respCode === 400 || approvalResp.respCode === 401) {
      onFailure("Sorry, you don't have the write permission!!")
    } else {
      onFailure(SERVER_ERR_MSG);
    }
  };
  const onSuccess = (title, body) => {
    setIsSuccess(true);
    setTitle(title);
    setDescription(body);
    modalRef.current.click();
  };

  const onFailure = (errMsg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(errMsg);
    modalRef.current.click();
  };

  const callback = () => {
    navigate("/partnerApproval");
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    //clearErrors(key);
  };

  const onError = (error, status) => {
    if (status === APPROVAL_STATUS_REJECT) {
      onSubmit(getValues(), status);
    } else {
      error && onFailure("Profile is not completed");
    }
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(
      partnerPrflDetails.partnerId,
      partnerPrflDetails.approvalUserTypeId
    );
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };
  const sucs = () => {};
  const download = (fileName) => {
    if (fileName !== "") {
      setShow(true);
      DownloadFile(fileName, sucs, sucs);
      setShow(false);
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="col-md-12 mt-3">
              {partnerPrflDetails.approveStatus === APPROVAL_STATUS_PENDING ? (
                <div className="alert alert-info">
                  <div>
                    <div className="float-start">
                      <h5 className="card-title">Approval Checklist</h5>
                      <p className="card-text">
                        {partnerPrflDetails.approvalChecklist}
                      </p>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-1 float-end"
                      onClick={getApprovalHistory}
                      data-bs-toggle="modal"
                      data-bs-target="#approval-history-modal"
                    >
                      Approval History
                    </button>
                    <div className="clearfix"></div>
                  </div>
                </div>
              ) : (
                <div className="float-end position-absolute end-0">
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet me-2"
                    onClick={getApprovalHistory}
                    data-bs-toggle="modal"
                    data-bs-target="#approval-history-modal"
                  >
                    Approval History
                  </button>
                </div>
              )}
              <div
                className="accordion form-register"
                id="accordionPanelsStayOpenExample"
              >
                <div>
                  <h5 className="theme-violet-txt">
                    {" "}
                    {isDriverPrfl ? "Driver" : "Partner"} Details{" "}
                  </h5>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="This field is mandatory"
                        >
                          {isDriverPrfl ? "Driver" : "Company"} Name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={
                            isDriverPrfl
                              ? partnerPrflDetails.driverName
                              : partnerPrflDetails.companyName
                          }
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          {isDriverPrfl ? "Driver" : "Company"} address{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.address}
                          maxLength="200"
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Regd Number{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={
                            isDriverPrfl
                              ? partnerPrflDetails.userInfoList[0]?.infoValue
                              : partnerPrflDetails.companyRegistrationNo
                          }
                          maxLength="20"
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Phone{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.phone}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Email{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.email}
                          disabled
                          maxLength="100"
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          State{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.state}
                          disabled
                          maxLength="100"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          City{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.city}
                          disabled
                          maxLength="100"
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Postal Code{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.postCode}
                          disabled
                          maxLength="100"
                        />
                      </div>
                      {isDriverPrfl && (
                        <>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Driver IC{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={partnerPrflDetails.ic}
                              disabled
                              maxLength="100"
                            />
                          </div>
                          <div className="col-md-4 mb-2 position-relative">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              IC Document{" "}
                            </label>
                            <div className="Upload-Photo w-100">
                              <div className="uploaded-text ms-2" disabled>
                                {GetSvgUpload({ required: false })}
                                {!!driverICData?.infoAttachment?.photoBucketUrl
                                  ? driverICData?.infoAttachment?.photoBucketUrl
                                  : "Upload IC"}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                              disabled={
                                typeof driverICData?.infoAttachment
                                  ?.photoBucketUrl === "undefined" ||
                                driverICData?.infoAttachment?.photoBucketUrl ===
                                  null
                              }
                              onClick={() =>
                                download(
                                  getFileName(
                                    driverICData?.infoAttachment?.photoBucketUrl
                                  )
                                )
                              }
                            >
                              {GetSvgIcon("IconFiledownload")}
                            </button>
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Driver License{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={driverLicenseData?.infoValue}
                              disabled
                              maxLength="100"
                            />
                          </div>
                          <div className="col-lg-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              License Expiry Date{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={driverLicenseData?.infoExpiry}
                              disabled
                              maxLength="100"
                            />
                          </div>
                          <div className="col-md-4 mb-2 position-relative">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              License{" "}
                            </label>
                            <div className="Upload-Photo w-100">
                              <div className="uploaded-text ms-2" disabled>
                                {GetSvgUpload({ required: false })}
                                {!!driverLicenseData?.infoAttachment
                                  ?.photoBucketUrl
                                  ? driverLicenseData?.infoAttachment
                                      ?.photoBucketUrl
                                  : "Upload IC"}
                              </div>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                              disabled={
                                typeof driverLicenseData?.infoAttachment
                                  ?.photoBucketUrl === "undefined" ||
                                driverLicenseData?.infoAttachment
                                  ?.photoBucketUrl === null
                              }
                              onClick={() =>
                                download(
                                  getFileName(
                                    driverLicenseData?.infoAttachment
                                      ?.photoBucketUrl
                                  )
                                )
                              }
                            >
                              {GetSvgIcon("IconFiledownload")}
                            </button>
                          </div>
                        </>
                      )}
                    </div>

                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#success-modal"
                      id="modalPopup"
                      className="d-none"
                      ref={modalRef}
                    />
                  </div>
                </div>

                <div className="my-4">
                  <h5 className="theme-violet-txt">Finance</h5>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-md-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="This field is mandatory"
                        >
                          Bank Name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.bankName}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Bank Account{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.bankAccount}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Beneficiary name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.beneficiaryName}
                          disabled
                        />
                      </div>
                      <div className="col-md-4 mb-2 position-relative">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          {" "}
                          Bank Statement{" "}
                        </label>
                        <div className="Upload-Photo w-100">
                          <div className="uploaded-text ms-2" disabled>
                            {GetSvgUpload({ required: false })}
                            {watch(
                              "bankDoc.photoBucketUrl",
                              "Upload Bank Statement"
                            )}
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                          disabled={
                            isDisabled[
                              `driverProfile.bankDoc.photoBucketUrl` +
                                "attachment"
                            ]
                          }
                          onClick={() =>
                            download(
                              getFileName(watch("bankDoc.photoBucketUrl"))
                            )
                          }
                        >
                          {GetSvgIcon("IconFiledownload")}
                        </button>
                      </div>
                      {partnerPrflDetails.partnerType ===
                        "Partner_Business" && (
                        <div className="col-md-4 mb-2 position-relative">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            {" "}
                            SSM Document{" "}
                          </label>
                          <div className="Upload-Photo w-100">
                            <div className="uploaded-text ms-2" disabled>
                              {GetSvgUpload({ required: false })}
                              {watch(
                                "ssmDoc.photoBucketUrl",
                                "Upload SSM Document"
                              )}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file"
                            disabled={
                              isDisabled[
                                `driverProfile.ssmDoc.photoBucketUrl` +
                                  "attachment"
                              ]
                            }
                            onClick={() =>
                              download(
                                getFileName(watch("ssmDoc.photoBucketUrl"))
                              )
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                
                {!isDriverPrfl && (
                  <div>
                    <h5 className="theme-violet-txt">SST Exemption</h5>
                    <div className="w-100">
                      <div className="row">
                        <div className="col-lg-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            SST Exempted{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={partnerPrflDetails.sstExempt ? 'Yes' : 'No'}
                            disabled
                          />
                        </div>
                        {partnerPrflDetails.sstExempt && (
                          <>
                            <div className="col-lg-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                title="This field is mandatory"
                              >
                                SST Number{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={partnerPrflDetails.sstNumber}
                                disabled
                              />
                            </div>
                            <div className="col-lg-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="This field is mandatory"
                              >
                                SST Start Date{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={partnerPrflDetails.sstExemptStartDate}
                                disabled
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isDriverPrfl && (
                  <div>
                    <h5 className="theme-violet-txt">Driver Document</h5>
                    {vhclList.map((item, index) => (
                      <div key={item.id} className="col-md-12 mb-3">
                        <div className="row">
                          <input
                            type="hidden"
                            {...register(`vehicleList.${index}.vhclId`)}
                          />
                          <div className="col-md-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              data-toggle="tooltip"
                              data-placement="right"
                              title="This field is mandatory"
                            >
                              Truck Type{" "}
                              {/* <span className="text-danger">&#9733;</span> */}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`vehicleList.${index}.vhclTypeName`)}
                              disabled
                            />
                          </div>

                          <div className="col-md-4 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                              title="This field is mandatory"
                            >
                              Truck Number{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`vehicleList.${index}.vhclNo`)}
                              disabled
                            />
                          </div>
                          <div className="clearfix"></div>
                          {/* Vehicle Info Details start */}
                          {item.vhclInfos.map((innerItem, innerIndex) => (
                            <>
                              {(innerItem.infoCode === "ROADTAX" ||
                                innerItem.infoCode === "PUSPAKOM" ||
                                innerItem.infoCode === "INSURANCE") && (
                                <>
                                  <div className="col-md-4 mb-2">
                                    <label
                                      htmlFor=""
                                      className="form-label theme-label"
                                      title="This field is mandatory"
                                    >
                                      {innerItem.infoDesc} Number{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `vehicleList.${index}.vhclInfos.${innerIndex}.infoValue`
                                      )}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-md-4 mb-2">
                                    <label
                                      htmlFor=""
                                      className="form-label theme-label w-100"
                                      title="This field is mandatory"
                                    >
                                      {innerItem.infoDesc} Expiry Date{" "}
                                    </label>
                                    <Controller
                                      className="w-100"
                                      control={control}
                                      name={`vehicleList.${index}.vhclInfos.${innerIndex}.expyDate`}
                                      defaultValue={item?.infoExpiry}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <ReactDatePicker
                                            placeholderText="License Expiry Date"
                                            disabled={true}
                                            className="form-control"
                                            dateFormat={DATE_FORMAT_CLIENT}
                                            selected={
                                              value ? new Date(value) : value
                                            }
                                            minDate={new Date()}
                                            onChange={(d) =>
                                              onChange(
                                                d !== null
                                                  ? moment(d).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : ""
                                              )
                                            }
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              <div className="col-md-4 mb-2 position-relative">
                                <label
                                  htmlFor=""
                                  className="form-label theme-label"
                                >
                                  {innerItem.infoDesc} Image
                                  {/* <span className="text-danger">&#9733;</span> */}
                                </label>
                                <div
                                  className="Upload-Photo w-100 mb-2"
                                  {...register(
                                    `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1`,
                                    {
                                      validate: (v) =>
                                        v.photoBucketUrl !== null &&
                                        v.photoBucketUrl !== "",
                                    }
                                  )}
                                >
                                  <input
                                    disabled
                                    type="file"
                                    onChange={(e) =>
                                      selectFile(
                                        `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1`,
                                        e
                                      )
                                    }
                                  />
                                  <div className="uploaded-text ms-2">
                                    {GetSvgUpload({ required: false })}
                                    {watch(
                                      `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                    ) === null ||
                                    watch(
                                      `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                    ) === ""
                                      ? `Upload ${innerItem.infoCode} Image`
                                      : getFileName(
                                          watch(
                                            `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                          )
                                        )}
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl` +
                                        "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    download(
                                      getFileName(
                                        watch(
                                          `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                        )
                                      )
                                    )
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                            </>
                          ))}
                        </div>
                        {/* Vehicle Info Details end */}
                        <div className="clearfix"></div>
                        {/* Vehicle Image */}
                        <div className="row">
                          <div className="col-md-3 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                            >
                              Vehicle Image (Front)
                            </label>
                            {imgData?.vhclImgF !== "" && (
                              <div className="w-100 text-center border border-1 max-img-100">
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    imgData?.vhclImgF
                                  }
                                  alt="img"
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                            >
                              Vehicle Image (Back)
                            </label>
                            {imgData?.vhclImgB !== "" && (
                              <div className="w-100 text-center border border-1 max-img-100">
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    imgData?.vhclImgB
                                  }
                                  alt="img"
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                            >
                              Vehicle Image (Left)
                            </label>
                            {imgData?.vhclImgL !== "" && (
                              <div className="w-100 text-center border border-1 max-img-100">
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    imgData?.vhclImgL
                                  }
                                  alt="img"
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                            >
                              Vehicle Image (Right)
                            </label>
                            {imgData?.vhclImgR !== "" && (
                              <div className="w-100 text-center border border-1 max-img-100">
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    imgData?.vhclImgR
                                  }
                                  alt="img"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 mb-2">
                            <label
                              htmlFor=""
                              className="form-label theme-label"
                            >
                              Vehicle Image (Internal)
                            </label>
                            {imgData?.vhclImgI !== "" && (
                              <div className="w-100 text-center border border-1 max-img-100">
                                <img
                                  src={
                                    "data:image/jpeg;base64," +
                                    imgData?.vhclImgI
                                  }
                                  alt="img"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {partnerPrflDetails.approveStatus !== APPROVAL_STATUS_PENDING && (
              <div className="col-lg-4 mb-2">
                <div className="float-end position-absolute end-0 p-2">
                  <button
                    type="button"
                    className="btn btn-default border me-3 floatEnd"
                    onClick={callbackHinstory}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {partnerPrflDetails.approveStatus === APPROVAL_STATUS_PENDING && (
              <div className="col-lg-4 mb-2">
                <label htmlFor="" className="form-label theme-label">
                  Remarks
                </label>
                <textarea
                  rows="3"
                  className="form-control"
                  {...register("remarks")}
                />
                {errors.remarks && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.remarks.message}
                    </span>
                  </div>
                )}
                <div className="float-end position-absolute end-0 p-2">
                  <button
                    type="button"
                    className="btn btn-default border me-3 floatEnd"
                    onClick={callbackHinstory}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-default border me-3 floatEnd"
                    data-bs-dismiss="modal"
                    onClick={handleSubmit(
                      (data) => onSubmit(data, LOG_TYPE_REJECT),
                      (err) => onError(err, LOG_TYPE_REJECT)
                    )}
                  >
                    Reject
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-secondary theme-violet"
                    onClick={handleSubmit(
                      (data) => onSubmit(data, LOG_TYPE_APPROVE),
                      (err) => onError(err, LOG_TYPE_APPROVE)
                    )}
                  >
                    Approve
                  </button>
                </div>
              </div>
            )}
            <ApprovalLogList data={approvalHistoryData} />
            <Spinner show={show} />
            <Popup
              isSuccess={isSuccess}
              title={title}
              description={description}
              handler={callback}
            />
          </main>
        </div>
      </div>
    </div>
  );
}
