import Header from "../Layout/Header";
import VehicleList from "./VehicleList";
import VehicleAddEdit from "./VehicleAddEdit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadVehicle from "./UploadVehicle";
import "./VehicleStyle.css";
import { useRef } from "react";
import Popup from "../Layout/Popup";
import { BTN_TYPE_ADD, SERVER_ERR_MSG } from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import Spinner from "../Layout/Spinner";
import { CallApiDelete, CallApiGet, CallApiPost } from "../Helper/serviceApi";
import CustomAlert from "../Layout/CustomAlert/CustomAlert";
import { LINEHAUL_PARTNER_COMPANY_USER_TYPE } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const initialHub = {
  id: 0,
  name: "",
  code: "",
  phone: "",
  address: "",
  sbyType: 0,
  sbuLat: 0.0,
  sbuLong: 0.0,
  parent: null,
};

const initialVhclType = {
  id: 0,
  vhclType: "",
  vhclSize: 0,
  maxWeight: 0,
  maxPallets: 0,
  palletHeight: 0,
  palletWeight: 0,
  maxPalletWeight: 0,
};

const initAttachment = {
  photo: "",
  photoFileName: "",
  photoFileType: "",
  photoBucketUrl: "",
};

export const initialVhclData = {
  vhclId: 0,
  vhclType: "",
  vhclNo: "",
  vhclOwner: "",
  vhclOwnerId: 0,
  allocatedTo: 0,
  transferSbu: 0,
  assignTo: 0,
  vhclLength: 0,
  transferTo: "",
  transferFrom: "",
  bdm: 0,
  btm: 0,
  tailGate: false,
  bondedType: 0,
  vhclImgF: Object.assign({}, initAttachment),
  vhclImgB: Object.assign({}, initAttachment),
  vhclImgL: Object.assign({}, initAttachment),
  vhclImgR: Object.assign({}, initAttachment),
  vhclImgCI: Object.assign({}, initAttachment),
  vhclImgBI: Object.assign({}, initAttachment),
  vhclInfos: [],
};

const inintHq = {
  id: 0,
  sbuCode: "",
  sbuName: "",
};

const initDropdownData = {
  vhclOwnerOpts: [],
  bondedTypeOpts: [],
};

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const VehicleMain = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [inlineAlert, setInlineAlert] = useState(false);
  const [inlineAlertMsg, setInlineAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [allocatedTo, setAllocatedTo] = useState([initialHub]);
  const [vhclTypes, setVhclTypes] = useState([initialVhclType]);
  const [vhclInfos, setVhclInfos] = useState([]);
  const [initialVhclInfos, setInitialVhclInfos] = useState([]);
  const [vhclData, setVhclData] = useState(
    JSON.parse(JSON.stringify(initialVhclData))
  );
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [vhclId, setVhclId] = useState(0);
  const navigate = useNavigate();
  const [vehicleList, setVehicleList] = useState([]);
  const [isVhclLoaded, setIsVhclLoaded] = useState(false);
  const [hq, setHq] = useState(JSON.parse(JSON.stringify(inintHq)));
  const [btnType, setBtnType] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;
  const [dropdownData, setDropdownData] = useState(
    Object.assign({}, initDropdownData)
  );

  useEffect(() => {
    loadHubs();
    loadVhclTypes();
    getHeadquater();
    fetchVhclInfos(0);
    loadDropdownOpts();
    // downloadVhclQr();
  }, []);

  const loadHubs = async () => {
    let data = await CallApiGet("getAllSbuList");
    if (data.respCode === 200 || data.respCode === 0) {
      setAllocatedTo(data.respData);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const getHeadquater = async () => {
    let data = await CallApiGet("getSbuHeadquater");
    if (data.respCode === 200 || data.respCode === 0) {
      setHq(data.respData[0]);
    } else {
      console.error(data.respMessage);
      onError();
    }
  };

  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");
    if (data.respCode === 200 || data.respCode === 0) {
      setVhclTypes(data.respData ? data.respData : []);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const loadDropdownOpts = async () => {
    const payload = ["BONDED_TYPE", "VEHICLE_OWNER_I"];
    let data = await CallApiPost("SysOptionGetByCode", payload);
    if (data.respCode === 200 || data.respCode === 0) {
      const ownerOpts = data.respData
        .filter((d) => d.optionCode === "VEHICLE_OWNER_I")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      const bondedTypeOpts = data.respData
        .filter((d) => d.optionCode === "BONDED_TYPE")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      setDropdownData({
        vhclOwnerOpts: ownerOpts,
        bondedTypeOpts: bondedTypeOpts,
      });
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const clearData = () => {
    setVhclData(JSON.parse(JSON.stringify(initialVhclData)));
    setVhclInfos(JSON.parse(JSON.stringify(initialVhclInfos)));
  };

  const fetchVhclInfos = async (vhclId) => {
    const uri = "getVehicleInfo";
    let data = await CallApiPost(uri, { vhclId });

    if (data.respCode === 200 || data.respCode === 0) {
      let infos = data.respData;
      infos.forEach((element) => {
        element.attachment1 = {
          photo: "",
          photoFileName:
            element.attachment1 === null ? "" : element.attachment1,
          photoFileType: "",
          photoBucketUrl: "",
        };
        element.id = element.id === null ? 0 : element.id;
        element.vhclId = element.vhclId === null ? 0 : element.vhclId;
        element.expyDate = element.expyDate === null ? "" : element.expyDate;
        element.remarks = element.remarks === null ? "" : element.remarks;
      });
      setVhclInfos([...infos]);
      if (vhclId === 0) {
        setInitialVhclInfos(infos);
      }
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const saveVhclData = async (saveData) => {
    setShow(true);
    if (saveData.allocatedTo === 0 || saveData.allocatedTo === "") {
      saveData.allocatedTo = hq.id;
    }

    saveData.partnerId = userProfileId;
    const uri = "saveVehicle";
    let data = await CallApiPost(uri, saveData);

    if (data.respCode === 200 || data.respCode === 0) {
      setShow(false);
      clearData();
      onSuccess();
    } else if (data.respCode === 409) {
      setShow(false);
      setInlineAlert(true);
      setAlertVariant("danger");
      setInlineAlertMsg(data.respMessage);
      setTimeout(() => {
        setInlineAlert(false);
      }, 5000);
    } else {
      setShow(false);
      onError();
      clearData();
    }
  };

  const fetchVehicles = async () => {
    setShow(true);
    let uri = "getVehicleList";
    let data = await CallApiPost(uri, { partnerId: userProfileId });
    if (data.respCode === 200 || data.respCode === 0) {
      setShow(false);
      if (data.respData.length > 0) {
        setVehicleList(data.respData);
        setIsVhclLoaded(true);
      } else {
        setIsVhclLoaded(false);
      }
    } else if (data.respCode === 400 || data.respCode === 401) {
      setShow(false);
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      setShow(false);
      console.error(data);
      onError();
    }
  };

  const deleteVhcl = async () => {
    const uri = "deleteVehicle?vhclId=" + vhclId;
    let resp = await CallApiDelete(uri);
    clearData();
    setShowAlert(false);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "", "Vehicle status updated successfully !!!");
        break;
      case 409:
        openPopup(false, "Error!", resp.respMessage);
        break;
      default:
        onError();
        break;
    }
  };

  const callback = () => {
    fetchVehicles();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const onSuccess = () => {
    setIsSuccess(true);
    setTitle("Congratulations!");
    setDescription("Vehicle saved successfully !!!");
    modalRef.current.click();
  };

  const onError = () => {
    setIsSuccess(false);
    setTitle("Error!");
    setDescription(SERVER_ERR_MSG);
    modalRef.current.click();
  };

  const onUploadErr = (resp) => {
    switch (resp?.respCode) {
      case 500:
        onError();
        break;
      case 401:
        setIsSuccess(false);
        setTitle("UNAUTHORIZED");
        setDescription("Sorry, you don't have the read permission!!");
        modalRef.current.click();
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  const handleActiveDeactive = (active, vhclNo) => {
    if (active) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate vehicle <b>{vhclNo}</b>
        </>
      );
      setAlertTitle("Deactivate Vehicle");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate vehicle <b>{vhclNo}</b>
        </>
      );
      setAlertTitle("Activate Vehicle");
    }
  };

  const downloadVhclQr = async (argData) => {
    setShow(true);
    document.body.click();
    const payload = {
      id: argData?.id,
      vhclNo: argData?.vhclNo,
    };
    const uri = `DownloadVehicleQr`;
    const resp = await CallApiPost(uri, payload);
    if (resp?.respCode === 200 && !!resp?.respData) {
      window.open(resp.respData, "_blank");
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to download. Please try after sometime!!";
      }
      // handleRespPostApiCall(resp);
      onError();
    }
    setShow(false);
  };
  // const handleRespPostApiCall = (resp) => {
  //   switch (resp.respCode) {
  //     case 200:
  //       showAlert({
  //         isSuccess: true,
  //         title: "SUCCESS",
  //         description: resp?.respMessage,
  //       });
  //       break;
  //     case 99:
  //       showAlert({
  //         isSuccess: false,
  //         title: "NETWORK ERROR",
  //         description: (
  //           <>
  //             We're having trouble connecting to the server. <br /> Please check
  //             your internet connection and try again.
  //           </>
  //         ),
  //       });
  //       break;
  //     case 500:
  //       showAlert({
  //         isSuccess: false,
  //         title: "ERROR",
  //         description:
  //           "Oops! Something went wrong while processing your request.",
  //       });
  //       break;
  //     default:
  //       showAlert({
  //         isSuccess: false,
  //         title: "ERROR",
  //         description: resp?.respMessage,
  //       });
  //       break;
  //   }
  // };

  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Vehicle</div>
              </div>
              <div className="float-end d-flex">
                <div className="mt-1">
                  <button
                    className="btn btn-outline-secondary theme-violet"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#upload-file-modal"
                  >
                    {GetSvgIcon("IconArrowup")} Upload
                  </button>
                </div>
                <div className="float-end mt-1">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap ms-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#vehicle-add-edit-modal"
                    onClick={() => setBtnType(BTN_TYPE_ADD)}
                  >
                    {GetSvgIcon("PlusCircle")} Add Vehicle
                  </button>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#success-modal"
                  id="modalPopup"
                  className="d-none"
                  ref={modalRef}
                />
              </div>
            </div>
            <VehicleList
              fetchVhclInfos={fetchVhclInfos}
              setVhclData={setVhclData}
              vehicleList={vehicleList}
              isVhclLoaded={isVhclLoaded}
              fetchVehicles={fetchVehicles}
              setBtnType={setBtnType}
              toggleAlert={toggleAlert}
              handleActiveDeactive={handleActiveDeactive}
              setVhclId={setVhclId}
              downloadVhclQr={downloadVhclQr}
            />
          </main>
        </div>
      </div>
      <VehicleAddEdit
        alloctatedTo={allocatedTo}
        vhclTypes={vhclTypes}
        vhclData={vhclData}
        vhclInfos={vhclInfos}
        saveVhclData={saveVhclData}
        clearData={clearData}
        hq={hq}
        btnType={btnType}
        inlineAlert={inlineAlert}
        inlineAlertMsg={inlineAlertMsg}
        alertVariant={alertVariant}
        dropdownData={dropdownData}
      />
      <UploadVehicle onSuccess={onSuccess} onUploadErr={onUploadErr} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={deleteVhcl}
        />
      )}
    </div>
  );
};

export default VehicleMain;
