import React, { useEffect, useState } from "react";
import GetSvgIcon from "../../images/svglist";
import {
  INVOICE_URL,
  STAFFMENU,
  CUSTMENU,
  INV,
  PREBOOKC,
  CUSTAPVL,
  PARTAPPRV,
  ISRAPVL,
  PMLIST,
  CMGLIST,
  ROUT,
  RTPLN,
  VHCLTYPE,
  VEHICLE,
  ROLE,
  SBU,
  USRMGM,
  ORDLIST,
  SHIPVIEW,
  LMP,
  TRKSHIP,
  INBORDC,
  COMMD,
  COMMI,
  CLAIM,
  RACH,
  SLA,
  APVLWF,
  INSTAX,
  HOLIDAY,
  CANCEL,
  CONTDOC,
  DIS,
  PROMO,
  ISRCOMMCONFIG,
  ISRLIST,
  SYSCFG,
  CMGDSHB,
  ADMDSHB,
  LCOPRTN,
  VFLG,
  INSPLG,
  ABOUTUS,
  REPORT_OPERATION,
  REPORT_FINANCE,
  CUSTOMER_USER_MANAGEMENT,
  LONGHAULCOMM,
  HOMECONFIG,
  INBRATECONFIG,
  SUPPLIERMGM,
  SUPPLIERMENU,
  SCHCHRATECONFIG,
  SCHCHROUTESCHEDULE,
  SCHCHROUTEPLAN,
  CHVIEWPLAN,
  CHORDERLIST,
  CN_URL,
  CUSTADDRBOOK,
  BSAPPRV,
  APP_BS_URL,
  PARTMENU,
  PARTNERSTAFFMGM,
  VHCLLEASE,
  VHCLLEASEFIN,
} from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { useNavigate } from "react-router-dom";

const MenuNavBar = () => {
  const [userPermissions, setUserPermissions] = useState([]);
  const [splPrivilege, setSplPrivilege] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setUserPermissions(JSON.parse(getUserInfo("userPermissions")));
    setSplPrivilege(JSON.parse(getUserInfo("splPrivilege")));
  }, []);

  const isAuthenticated = (argFeatCode) => {
    const uPerm = userPermissions?.find((up) => up.featureCode === argFeatCode);
    return !!uPerm ? uPerm?.allowedRead : false;
  };

  return (
    <div
      className="offcanvas offcanvas-start"
      tabIndex="-1"
      id="offcanvasWithBackdrop"
      aria-labelledby="offcanvasWithBackdrop"
    >
      <div className="offcanvas-header py-0">
        <h5 id="offcanvasWithBackdrop">
          <div
            className="float-start mt-3"
            onClick={() => navigate("/", { replace: true })}
          >
            {GetSvgIcon("Home")}
          </div>
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>

      <div className="offcanvas-body">
        <nav>
          <ul
            className="list-group list-group-flush "
            aria-labelledby="dropdownUser2"
          >
            {(isAuthenticated(CMGDSHB) || isAuthenticated(ADMDSHB)) && (
              <li className="list-group-item ">
                <a className="dropdown-item " href={`/dashboard`}>
                  {GetSvgIcon("IconDashboard")} Dashboard
                </a>
              </li>
            )}

            {isAuthenticated(STAFFMENU) && (
              <>
                {/* Administration */}
                {(isAuthenticated(ROLE) ||
                  isAuthenticated(SBU) ||
                  isAuthenticated(USRMGM)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample2"
                    >
                      {GetSvgIcon("IconAdmin")} Administration
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample2"
                    >
                      <ul>
                        {isAuthenticated(ROLE) && (
                          <li>
                            <a className="dropdown-item" href="/securityRoles">
                              {GetSvgIcon("BiShieldLockFill")} Roles
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SBU) && (
                          <li>
                            <a className="dropdown-item" href={`/sbu`}>
                              {GetSvgIcon("IconSbu")} SBU
                            </a>
                          </li>
                        )}
                        {isAuthenticated(USRMGM) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href={`/userManagement`}
                            >
                              {GetSvgIcon("StaffMgm")} LineHaul Staff
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Order Management */}
                {(isAuthenticated(ORDLIST) ||
                  isAuthenticated(SHIPVIEW) ||
                  isAuthenticated(LMP) ||
                  isAuthenticated(TRKSHIP) ||
                  isAuthenticated(PREBOOKC) ||
                  isAuthenticated(INBORDC) ||
                  isAuthenticated(LCOPRTN) ||
                  isAuthenticated(CHORDERLIST)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#orderMgm"
                      role="button"
                      aria-expanded="false"
                      aria-controls="orderMgm"
                    >
                      {GetSvgIcon("CardChecklist")} Order Management
                    </a>
                    <div className="collapse main-submenu" id="orderMgm">
                      <ul>
                        {isAuthenticated(ORDLIST) && (
                          <li>
                            <a className="dropdown-item" href="/customerOrder">
                              {GetSvgIcon("ListOl")} Order List
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SHIPVIEW) && (
                          <li>
                            <a className="dropdown-item" href="/shipmentView">
                              {GetSvgIcon("ShipmentView")} Shipment View
                            </a>
                          </li>
                        )}
                        {isAuthenticated(LMP) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/lastMilePlanning"
                            >
                              {GetSvgIcon("ShipmentView")} Last Mile Planning
                            </a>
                          </li>
                        )}
                        {isAuthenticated(LMP) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/ftlLastMilePlanning"
                            >
                              {GetSvgIcon("ShipmentView")} Full Truck Assign
                            </a>
                          </li>
                        )}
                        {isAuthenticated(LMP) && (
                          <li>
                            <a className="dropdown-item" href="/bidList">
                              {GetSvgIcon("ShipmentView")} Bid Management
                            </a>
                          </li>
                        )}
                        {isAuthenticated(TRKSHIP) && (
                          <li>
                            <a className="dropdown-item" href="/trackWaybill">
                              {GetSvgIcon("Binoculars")} Track Shipment
                            </a>
                          </li>
                        )}
                        {isAuthenticated(PREBOOKC) && (
                          <li className="dropdown-item">
                            <a className="dropdown-item " href={`/preBooking`}>
                              {GetSvgIcon("BiCartCheckFill")} Pre Booking
                            </a>
                          </li>
                        )}
                        {isAuthenticated(INBORDC) && (
                          <li>
                            <a className="dropdown-item" href="/inboundorder">
                              {GetSvgIcon("ListOl")} International Inbound Order
                            </a>
                          </li>
                        )}

                        {isAuthenticated(CHORDERLIST) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/charteredOrders"
                            >
                              {GetSvgIcon("ListOl")} Chartered Orders
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Claim Management */}
                {isAuthenticated(CLAIM) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item" href="/claim">
                      {GetSvgIcon("BiClipboardCheckFill")} Claims
                    </a>
                  </li>
                )}
                {/* Staff Invoice */}
                {isAuthenticated(INV) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={INVOICE_URL}>
                      {GetSvgIcon("BiCoin")} Invoice
                    </a>
                  </li>
                )}
                {isAuthenticated(INV) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={CN_URL}>
                      {GetSvgIcon("BiCoin")} Credit Note
                    </a>
                  </li>
                )}
                {isAuthenticated(BSAPPRV) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={APP_BS_URL}>
                      {GetSvgIcon("BiCoin")} Approve Bank Slip
                    </a>
                  </li>
                )}
                {/* Commission  */}
                {(isAuthenticated(COMMD) || isAuthenticated(COMMI)) && (
                  <li className="list-group-item">
                    <a className="dropdown-item " href={`commission`}>
                      {GetSvgIcon("CommissionIcon")} Commission
                    </a>
                  </li>
                )}
                {/* Supplier Management */}
                {isAuthenticated(SUPPLIERMGM) && (
                  <li className="list-group-item">
                    <a className="dropdown-item" href="/suppliermanagement">
                      {GetSvgIcon("ServiceMgmt")} Supplier Management
                    </a>
                  </li>
                )}
                {/* Service Management */}
                {(isAuthenticated(RACH) ||
                  isAuthenticated(SLA) ||
                  isAuthenticated(INBRATECONFIG) ||
                  isAuthenticated(SCHCHRATECONFIG)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#svcmgm"
                      role="button"
                      aria-expanded="false"
                      aria-controls="svcmgm"
                    >
                      {GetSvgIcon("ServiceMgmt")} Service Management
                    </a>

                    <div className="collapse main-submenu" id="svcmgm">
                      <ul>
                        {isAuthenticated(RACH) && (
                          <li>
                            <a className="dropdown-item" href="/rateChart">
                              {GetSvgIcon("IconCoin")} Rate Chart
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SLA) && (
                          <li>
                            <a className="dropdown-item" href="/service">
                              {GetSvgIcon("IconCoin")} SLA
                            </a>
                          </li>
                        )}
                        {/* <li>
                        <a className="dropdown-item" href="/workInProgress">
                          {GetSvgIcon("Report")} Failure Report
                        </a>
                      </li> */}
                        {isAuthenticated(INBRATECONFIG) && (
                          <li className="dropdown-item">
                            <a
                              className="dropdown-item "
                              href={`/inbOrdRateConfig`}
                            >
                              {GetSvgIcon("CurrencyExg")} Inbound Rate
                              Configuration
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SLA) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/AgentCustomerCharges"
                            >
                              {GetSvgIcon("IconCoin")} Agent Customer Charges
                            </a>
                          </li>
                        )}

                        {/* {isAuthenticated(SCHCHRATECONFIG) && (
                          <li className="dropdown-item">
                            <a
                              className="dropdown-item "
                              href={`/charteredRate`}
                            >
                              {GetSvgIcon("CurrencyExg")} Scheduled Chartered
                              Rate
                            </a>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Vehicle Management */}
                {(isAuthenticated(VHCLTYPE) ||
                  isAuthenticated(VEHICLE) ||
                  isAuthenticated(VHCLLEASE) ||
                  isAuthenticated(VHCLLEASEFIN)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExampleVehicleMgm"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExampleVehicleMgm"
                    >
                      {GetSvgIcon("VehicleTruck")} Vehicle management
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExampleVehicleMgm"
                    >
                      <ul>
                        {isAuthenticated(VHCLTYPE) && (
                          <li>
                            <a className="dropdown-item" href={`/vehicleType`}>
                              {GetSvgIcon("MainTruck")} Vehicle Type
                            </a>
                          </li>
                        )}
                        {isAuthenticated(VEHICLE) && (
                          <li>
                            <a className="dropdown-item" href={`/vehicle`}>
                              {GetSvgIcon("IconTruck")} Vehicle
                            </a>
                          </li>
                        )}
                        {/* <li>
                        <a className="dropdown-item" href="/workInProgress">
                          {GetSvgIcon("IconSbu")} Trip Log
                        </a>
                    </li>*/}
                        {isAuthenticated(VFLG) && (
                          <li>
                            <a className="dropdown-item" href={`/fuellog`}>
                              {GetSvgIcon("IconSbu")} Fuel Log
                            </a>
                          </li>
                        )}
                        {isAuthenticated(INSPLG) && (
                          <li>
                            <a className="dropdown-item" href={`/inspection`}>
                              {GetSvgIcon("IconSbu")} Inspection Log
                            </a>
                          </li>
                        )}
                        {isAuthenticated(VHCLLEASE) && (
                          <li>
                            <a className="dropdown-item" href={`/vhclLease`}>
                              {GetSvgIcon("IconSbu")} Lease Vehicle
                            </a>
                          </li>
                        )}
                        {isAuthenticated(VHCLLEASEFIN) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href={`/vehicleApproval`}
                            >
                              {GetSvgIcon("IconSbu")} Vehicle Approval
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Route Management */}
                {(isAuthenticated(ROUT) ||
                  isAuthenticated(RTPLN) ||
                  isAuthenticated(SCHCHROUTESCHEDULE) ||
                  isAuthenticated(SCHCHROUTEPLAN)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample1Route"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1Route"
                    >
                      {GetSvgIcon("IconConfig")} Route Management
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample1Route"
                    >
                      <ul>
                        {isAuthenticated(ROUT) && (
                          <li>
                            <a className="dropdown-item" href={`/route`}>
                              {GetSvgIcon("IconGeoalt")} Route
                            </a>
                          </li>
                        )}
                        {isAuthenticated(RTPLN) && (
                          <li>
                            <a className="dropdown-item" href={`/routePlan`}>
                              {GetSvgIcon("RouteSchedule")} Route Schedule
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SCHCHROUTEPLAN) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href={`/charteredRoute`}
                            >
                              {GetSvgIcon("IconGeoalt")} Chartered Route
                            </a>
                          </li>
                        )}
                        {isAuthenticated(SCHCHROUTESCHEDULE) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href={`/charteredSchedule`}
                            >
                              {GetSvgIcon("RouteSchedule")} Chartered Schedule
                            </a>
                          </li>
                        )}

                        {/* <li>
                        <a className="dropdown-item" href="/workInProgress">
                          {GetSvgIcon("History")} History
                        </a>
                      </li> */}
                      </ul>
                    </div>
                  </li>
                )}
                {/* {Home Configuration} */}
                {isAuthenticated(HOMECONFIG) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample1HomeConfig"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample1HomeConfig"
                    >
                      {GetSvgIcon("BiHouseGearFill")} Home Page Configuration
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample1HomeConfig"
                    >
                      <ul>
                        <li className="dropdown-item">
                          <a
                            className="dropdown-item "
                            href={`/home-notification-config`}
                          >
                            {GetSvgIcon("IconHeaderBell")} Notification
                          </a>
                        </li>
                        <li className="dropdown-item">
                          <a
                            className="dropdown-item "
                            href={`/home-advertisement-config`}
                          >
                            {GetSvgIcon("BiBadgeAd")} Advertisement
                          </a>
                        </li>
                        <li className="dropdown-item">
                          <a
                            className="dropdown-item "
                            href={`/home-news-bulletin-config`}
                          >
                            {GetSvgIcon("PayBill")} News And Bulletin
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
                {/* Configuration */}
                {(isAuthenticated(SYSCFG) ||
                  isAuthenticated(APVLWF) ||
                  isAuthenticated(INSTAX) ||
                  isAuthenticated(HOLIDAY) ||
                  isAuthenticated(CANCEL) ||
                  isAuthenticated(CONTDOC) ||
                  isAuthenticated(DIS) ||
                  isAuthenticated(PROMO) ||
                  isAuthenticated(ISRCOMMCONFIG)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#config"
                      role="button"
                      aria-expanded="false"
                      aria-controls="config"
                    >
                      {GetSvgIcon("IconSystemconfig")} Configuration
                    </a>

                    <div className="collapse main-submenu" id="config">
                      <ul>
                        {isAuthenticated(SYSCFG) && (
                          <li>
                            <a className="dropdown-item" href="/globalConfig">
                              {GetSvgIcon("Gear")} Global Configuration
                            </a>
                          </li>
                        )}
                        {isAuthenticated(APVLWF) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/approvalWorkFlow"
                            >
                              {GetSvgIcon("IconApprovalworkflow")} Approval
                              Workflow
                            </a>
                          </li>
                        )}
                        {isAuthenticated(INSTAX) && (
                          <li>
                            <a className="dropdown-item" href="/taxConfig">
                              {GetSvgIcon("IconTaxconfig")} Insurance and Tax
                            </a>
                          </li>
                        )}
                        {isAuthenticated(HOLIDAY) && (
                          <li>
                            <a className="dropdown-item" href="/holiday">
                              {GetSvgIcon("IconFileimage")} Holiday
                            </a>
                          </li>
                        )}
                        {isAuthenticated(CANCEL) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/cancellationConfig"
                            >
                              {GetSvgIcon("Cancel")} Cancellation
                            </a>
                          </li>
                        )}
                        {isAuthenticated(CONTDOC) && (
                          <li>
                            <a className="dropdown-item" href={`/shipment`}>
                              {GetSvgIcon("BiArchiveFill")} Content & Document
                            </a>
                          </li>
                        )}
                        {isAuthenticated(DIS) && (
                          <li>
                            <a className="dropdown-item" href={`/discount`}>
                              {GetSvgIcon("BiTags")} Discount
                            </a>
                          </li>
                        )}
                        {isAuthenticated(PROMO) && (
                          <li>
                            <a className="dropdown-item" href={`/promotion`}>
                              {GetSvgIcon("PayBill")} Promotion
                            </a>
                          </li>
                        )}
                        {isAuthenticated(ISRCOMMCONFIG) && (
                          <li className="dropdown-item">
                            <a
                              className="dropdown-item "
                              href={`/isrCommissionConfig`}
                            >
                              {GetSvgIcon("CommissionIcon")} ISR Commission
                            </a>
                          </li>
                        )}

                        {isAuthenticated(LONGHAULCOMM) && (
                          <li className="dropdown-item">
                            <a className="dropdown-item " href={`/longhaul`}>
                              {GetSvgIcon("CommissionIcon")} Longhaul Commission
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Customer Management */}
                {(isAuthenticated(CMGLIST) ||
                  isAuthenticated(CUSTAPVL) ||
                  isAuthenticated(CUSTOMER_USER_MANAGEMENT)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample5"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5"
                    >
                      {GetSvgIcon("CustomerMgmt")} Customer Management
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample5"
                    >
                      <ul>
                        {isAuthenticated(CMGLIST) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/customerManagement?type=ALL"
                            >
                              {GetSvgIcon("IconPersonlinesfill")} Customer List
                            </a>
                          </li>
                        )}
                        {isAuthenticated(CUSTAPVL) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/approvalTask?type=ALL"
                            >
                              {GetSvgIcon("IconPersonfillcheck")}Customer
                              Approvals
                            </a>
                          </li>
                        )}
                        {isAuthenticated(CUSTOMER_USER_MANAGEMENT) && (
                          <li>
                            <a className="dropdown-item" href="/customer-user">
                              {GetSvgIcon("IconPersonlinesfill")} Customer Staff
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Partner Management */}
                {(isAuthenticated(PMLIST) || isAuthenticated(PARTAPPRV)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample5Partner"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5Partner"
                    >
                      {GetSvgIcon("IconPartners")} Partner Management
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample5Partner"
                    >
                      <ul>
                        {isAuthenticated(PMLIST) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/partnerManagement"
                            >
                              {GetSvgIcon("IconPersonlinesfill")} Partner List
                            </a>
                          </li>
                        )}
                        {isAuthenticated(PARTAPPRV) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/partnerApproval"
                            >
                              {GetSvgIcon("IconPersonfillcheck")} Approvals
                            </a>
                          </li>
                        )}
                        {isAuthenticated(PARTNERSTAFFMGM) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/partnerStaffManagement"
                            >
                              {GetSvgIcon("IconPersonlinesfill")} Partner Staff
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Sales Partner Management */}
                {(isAuthenticated(CMGLIST) || isAuthenticated(CUSTAPVL)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExample5Agent"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExample5Agent"
                    >
                      {GetSvgIcon("CustomerMgmt")} Sales Partner Management
                    </a>

                    <div
                      className="collapse main-submenu"
                      id="collapseExample5Agent"
                    >
                      <ul>
                        {isAuthenticated(CMGLIST) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/customerManagement?type=AC"
                            >
                              {GetSvgIcon("IconPersonlinesfill")} Sales Partner
                              List
                            </a>
                          </li>
                        )}
                        {isAuthenticated(CUSTAPVL) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/approvalTask?type=AC"
                            >
                              {GetSvgIcon("IconPersonfillcheck")}Sales Partner
                              Approvals
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* ISR Management */}
                {(isAuthenticated(ISRLIST) || isAuthenticated(ISRAPVL)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExampleISR"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExampleISR"
                    >
                      {GetSvgIcon("IconPartners")} ISR Management
                    </a>
                    <div
                      className="collapse main-submenu"
                      id="collapseExampleISR"
                    >
                      <ul>
                        {isAuthenticated(ISRLIST) && (
                          <li>
                            <a className="dropdown-item" href="/isrActivation">
                              {GetSvgIcon("IconCustomer")} ISR List
                            </a>
                          </li>
                        )}
                        {isAuthenticated(ISRAPVL) && (
                          <li>
                            <a className="dropdown-item" href="/isrApproval">
                              {GetSvgIcon("IconPersonfillcheck")} Approvals
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/* Reports */}
                {(isAuthenticated(REPORT_FINANCE) ||
                  isAuthenticated(REPORT_OPERATION)) && (
                  <li className="list-group-item">
                    <a
                      className="dropdown-item dropdown-toggle"
                      data-bs-toggle="collapse"
                      href="#collapseExampleReport"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseExampleReport"
                    >
                      {GetSvgIcon("IconPartners")} Reports
                    </a>
                    <div
                      className="collapse main-submenu"
                      id="collapseExampleReport"
                    >
                      <ul>
                        {isAuthenticated(REPORT_OPERATION) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/operetional-report"
                            >
                              {GetSvgIcon("IconCustomer")} Operational
                            </a>
                          </li>
                        )}
                        {isAuthenticated(REPORT_FINANCE) && (
                          <li>
                            <a
                              className="dropdown-item"
                              href="/finantial-report"
                            >
                              {GetSvgIcon("IconPersonfillcheck")} Financial
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </li>
                )}
                {/*Customer Address Book Management*/}
                {isAuthenticated(CUSTADDRBOOK) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/addressBook`}>
                      {GetSvgIcon("BiClipboardCheckFill")}Customer Address Book
                    </a>
                  </li>
                )}
              </>
            )}

            {isAuthenticated(PARTMENU) && (
              <>
                {isAuthenticated(VEHICLE) && (
                  <li className="list-group-item">
                    <a className="dropdown-item" href={`/vehicle`}>
                      {GetSvgIcon("IconTruck")} Vehicle Management
                    </a>
                  </li>
                )}
                {isAuthenticated(PARTNERSTAFFMGM) && (
                  <li className="list-group-item ">
                    <a
                      className="dropdown-item"
                      href={`/partnerStaffManagement`}
                    >
                      {GetSvgIcon("StaffMgm")} Staff Management
                    </a>
                  </li>
                )}
              </>
            )}

            {isAuthenticated(CUSTMENU) && (
              <>
                {/* {isAuthenticated(VEHICLE) && (
                  <li className="list-group-item">
                    <a className="dropdown-item" href={`/vehicle`}>
                      {GetSvgIcon("IconTruck")} Vehicle Management
                    </a>
                  </li>
                )}
                {isAuthenticated(USRMGM) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item" href={`/userManagement`}>
                      {GetSvgIcon("StaffMgm")} Staff Management
                    </a>
                  </li>
                )} */}

                {isAuthenticated(ORDLIST) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/customerOrder`}>
                      {GetSvgIcon("BiCart4")} My Orders
                    </a>
                  </li>
                )}

                {isAuthenticated(INBORDC) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/inboundorder`}>
                      {GetSvgIcon("BiCart4")} International Inbound Order
                    </a>
                  </li>
                )}

                {isAuthenticated(INV) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={INVOICE_URL}>
                      {GetSvgIcon("BiCoin")} Invoice
                    </a>
                  </li>
                )}
                {isAuthenticated(INV) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={CN_URL}>
                      {GetSvgIcon("BiCoin")} Credit Note
                    </a>
                  </li>
                )}

                {isAuthenticated(CLAIM) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/claim`}>
                      {GetSvgIcon("BiClipboardCheckFill")} Claims
                    </a>
                  </li>
                )}
                {splPrivilege && isAuthenticated(PREBOOKC) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/preBooking`}>
                      {GetSvgIcon("BiCartCheckFill")} Pre Booking
                    </a>
                  </li>
                )}
                {isAuthenticated(CUSTOMER_USER_MANAGEMENT) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/customer-user`}>
                      {GetSvgIcon("StaffMgm")} Staff Management
                    </a>
                  </li>
                )}
                {isAuthenticated(CHVIEWPLAN) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/charteredSchedule`}>
                      {GetSvgIcon("IconTruck")} Chartered Schedules
                    </a>
                  </li>
                )}
                {isAuthenticated(CHORDERLIST) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/charteredOrders`}>
                      {GetSvgIcon("BiCart4")} Chartered Orders
                    </a>
                  </li>
                )}
                {isAuthenticated(CUSTADDRBOOK) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/addressBook`}>
                      {GetSvgIcon("BiClipboardCheckFill")} Address Book
                      Management
                    </a>
                  </li>
                )}
              </>
            )}
            {isAuthenticated(SUPPLIERMENU) && (
              <>
                {isAuthenticated(INBORDC) && (
                  <li className="list-group-item ">
                    <a className="dropdown-item " href={`/inboundorder`}>
                      {GetSvgIcon("BiCart4")} International Inbound Order
                    </a>
                  </li>
                )}
              </>
            )}
            {/* About Us */}
            <li className="list-group-item">
              {isAuthenticated(ABOUTUS) && (
                <a className="dropdown-item" href="/aboutus">
                  {GetSvgIcon("IconPersonfillcheck")} About Us{" "}
                </a>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MenuNavBar;
