import React, { useEffect, useState } from "react";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Layout from "../../Layout/Layout";
import VehApprovalList from "./VehApprovalList";
import { initialVhclData } from "../../Vehicle/VehicleMain";
import VehApprovalAction from "./VehApprovalAction";
import { VEHICLE_IMAGE_CODE_MAP } from "../../../ConfigConstants/ConfigConstants";
import ModalConfirmAlert from "../../Layout/ModalConfirmAlert";
import { TEXT_AREA_REGEX } from "../../Helper/Constants";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
  dialogClassName: "",
  data: null,
};

const initApprovalEntity = {
  vhclId: 0,
  vehicleNo: "V",
  vhclType: "",
  prtId: 0,
  partner: "",
  isActive: false,
  status: "",
  statusId: 0,
  approverRole: 0,
  assignedOn: "",
  approvalStepId: 0,
  approvalType: 0,
  approvalEntityId: 0,
  approvalStatus: 0,
  remarks: "",
};

const VehApprovalMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vehApprovalList, setVehApprovalList] = useState([]);
  const [actionModal, setActionModal] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vhclInfo, setVhclInfo] = useState(Object.assign({}, initialVhclData));
  const [confirmConfig, setConfirmConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [selApprovalEntity, setSelApprovalEntity] = useState(
    Object.assign({}, initApprovalEntity)
  );

  useEffect(() => {
    fetchApproval();
  }, []);

  const openActionModal = (argData) => {
    setSelApprovalEntity(argData);
    setActionModal({
      size: "fullscreen",
      show: true,
      title: "Vehicle Lease",
    });
    fetchVhclInfos(argData.vhclId);
  };

  const fetchVhclInfos = async (vhclId) => {
    setSpinner(true);
    const uri = "GetVhclInfoById/" + vhclId;
    let resp = await CallApiGet(uri);

    if (
      (resp.respCode === 200 || resp.respCode === 0) &&
      Array.isArray(resp?.respData)
    ) {
      let respVhcl = resp?.respData[0];
      const vhclImgF = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgB = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgL = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgR = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgBI = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgCI = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      respVhcl.vhclImgF = vhclImgF;
      respVhcl.vhclImgB = vhclImgB;
      respVhcl.vhclImgL = vhclImgL;
      respVhcl.vhclImgR = vhclImgR;
      respVhcl.vhclImgCI = vhclImgCI;
      respVhcl.vhclImgBI = vhclImgBI;
      respVhcl.vhclInfos = JSON.parse(respVhcl.vhclInfos);
      setVhclInfo({ ...respVhcl });
    } else {
      setVhclInfo(Object.assign({}, initialVhclData));
      if (resp.respCode === 200 || resp.respCode === 0) {
        resp.respCode = 9;
      }
      handleApiResponse(resp);
    }
    setSpinner(false);
  };

  const closeActionModal = () => {
    setActionModal({
      size: "fullscreen",
      show: false,
      title: "",
      data: null,
      dialogClassName: "",
    });
    setVhclInfo(Object.assign({}, initialVhclData));
  };

  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchApproval();
  };

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const fetchApproval = async () => {
    setSpinner(true);
    const resp = await CallApiGet("ListVehicleApprovals");
    if (resp.respCode === 200) {
      setVehApprovalList(!!resp.respData ? resp.respData : []);
    } else {
      handleApiResponse(resp);
    }
    setSpinner(false);
  };

  const hideConfirmAlert = (handleBackdropOpacity = true) => {
    setConfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
      data: null,
    });
    if (handleBackdropOpacity) {
      setActionModal({ ...actionModal, dialogClassName: "" });
    }
  };

  const openConfirmAlert = (argData) => {
    setSelApprovalEntity(Object.assign({}, argData));
    setConfirmConfig({
      size: "md",
      show: true,
      title: argData?.title,
      data: argData,
    });
    setActionModal({ ...actionModal, dialogClassName: "opacity-css" });
  };

  const handleApprovalAction = async () => {
    if (selApprovalEntity?.remarks.length === 0) {
      setSelApprovalEntity({ ...selApprovalEntity, error: { remarks: true } });
      return;
    } else {
      setSelApprovalEntity({ ...selApprovalEntity, error: { remarks: false } });
    }
    setSpinner(true);
    const uri = `LogMasterApproval/${selApprovalEntity.approvalType}`;
    const resp = await CallApiPost(uri, selApprovalEntity);
    handleApiResponse(resp);
    hideConfirmAlert(false);
    closeActionModal();
    setSpinner(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (TEXT_AREA_REGEX.test(value)) {
      let copyEntity = { ...selApprovalEntity };
      copyEntity[name] = value;
      copyEntity.error = { remarks: false };
      setSelApprovalEntity(Object.assign({}, copyEntity));
    }
  };

  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={closePopup}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">VEHICLE APPROVALS</div>
        </div>
      </div>
      <VehApprovalList
        vehApprovalList={vehApprovalList}
        openActionModal={openActionModal}
      />
      <VehApprovalAction
        modalConfig={actionModal}
        handleModalClose={closeActionModal}
        vhclInfo={vhclInfo}
        handleOnClick={openConfirmAlert}
        selApprovalEntity={selApprovalEntity}
      />
      <ModalConfirmAlert
        confirmAlertConfig={confirmConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={handleApprovalAction}
      >
        <div className="row">
          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Reason <span className="text-danger">&#9733;</span>
            </label>
          </div>
          <div className="col-md-9 mb-2">
            <input
              id="cancellation-reason"
              type="text"
              className="form-control"
              name="remarks"
              value={selApprovalEntity?.remarks}
              autoComplete="false"
              onChange={(e) => handleOnChange(e)}
            />
            {!!selApprovalEntity?.error && selApprovalEntity?.error.remarks && (
              <div style={{ textAlign: "left" }}>
                <span className="badge bg-danger">
                  &#9888; This field is required.
                </span>
              </div>
            )}
          </div>
        </div>
      </ModalConfirmAlert>
    </Layout>
  );
};

export default VehApprovalMain;
