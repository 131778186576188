import React from "react";
import { DownloadFile } from "../Helper/FileStoreService";
import { Controller } from "react-hook-form";
import { toBase64 } from "../Helper/CommonMethods";
import { Col, Row } from "react-bootstrap";
import { DATE_FORMAT_CLIENT, REQUIRED_ERR_MSG } from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";

const VehicleInfo = ({
  vhclData,
  vhclInfoArr,
  control,
  register,
  setValue,
  getValues,
  watch,
  replace,
  update,
  isReadOnly = false,
  isMandatory = false
}) => {
  const watchVhclId = watch("vhclId");
  const selectFile = async (code, index, event) => {
    const file = event.target.files;
    let copyVhclInfos = watch("vhclInfos");
    let currVhclInfo = copyVhclInfos[index];
    if (file.length === 0) {
      const newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
      currVhclInfo.attachment1 = { ...newAttachment };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }
      const newAttachment = {
        photo: photo,
        photoFileName: code,
        photoFileType: ext,
        photoBucketUrl: "",
      };
      currVhclInfo.attachment1 = { ...newAttachment };
    }
    copyVhclInfos[index] = { ...currVhclInfo };
    // replace([...copyVhclInfos]);
    update(index, { ...currVhclInfo });
  };

  const postDownload = () => {
    alert("File Downloaded Successfully!");
  };

  return (
    <Row>
      {vhclInfoArr?.map((info, index) => (
        <Col md={2} key={info.id}>
          <br />
          <div key={index} className="info-border-container w-100">
            <div className="info-border-text theme-label  fw-bold font-12">
              {info.infoDesc}
            </div>
            <Row className="pb-2">
              {info.infoCode !== "GRANT" && (
                <Col md={12} className="pb-2">
                  <label className="theme-label">Expired On</label>
                  <div>
                    <Controller
                      control={control}
                      name={`vhclInfos.${index}.expyDate`}
                      defaultValue={info.expyDate}
                      rules={{
                        required: {
                          value: false,
                          message: REQUIRED_ERR_MSG,
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <ReactDatePicker
                          placeholderText="dd-mm-yyyy"
                          className="form-control"
                          wrapperClassName="w-100"
                          dateFormat={DATE_FORMAT_CLIENT}
                          selected={value ? new Date(value) : value}
                          showTimeSelect={false}
                          onChange={(d) =>
                            onChange(
                              d !== null ? moment(d).format("YYYY-MM-DD") : ""
                            )
                          }
                          minDate={new Date()}
                          showYearDropdown
                          disabled={isReadOnly}
                        />
                      )}
                    />
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col key={info.infoCode} md={12}>
                <div {...register(`vhclInfos.${index}.attachment1`)}>
                  <div className="theme-violet-txt d-flex justify-content-center align-items-center">
                    <span
                      type="button"
                      className="position-relative d-inline-block cursor-pointer"
                    >
                      <input
                        type="file"
                        className="position-absolute w-100 h-100 top-0 start-0 opacity-0 cursor-pointer"
                        aria-describedby="button-addon2"
                        accept="application/pdf, image/jpeg, image/jpg"
                        id={info.infoCode + "attachment"}
                        onChange={(e) => selectFile(info.infoCode, index, e)}
                        disabled={isReadOnly}
                      />
                      <span className="cursor-pointer">
                        {GetSvgIcon("BiFileEarmarkMed")}
                      </span>
                    </span>
                    <span
                      type="button"
                      className={
                        watch(`vhclInfos.${index}.attachment1`)?.photo?.length >
                        0
                          ? "text-black ps-1"
                          : "text-primary ps-1"
                      }
                      disabled={
                        watch(`vhclInfos.${index}.attachment1`)?.photo?.length >
                        0
                      }
                      onClick={() =>
                        DownloadFile(
                          vhclData.vhclId +
                            "_" +
                            info.attachment1.photoFileName,
                          postDownload
                        )
                      }
                      title={
                        "Download - " +
                        watch(`vhclInfos.${index}.attachment1.photoFileName`)
                      }
                    >
                      {GetSvgIcon("BiArrowDown")}
                    </span>
                  </div>
                  <span className=" text-center text-truncate">
                    <p className="m-0">
                      {watch(`vhclInfos.${index}.attachment1.photoFileName`)
                        ?.length > 0 &&
                        watch(`vhclInfos.${index}.attachment1.photoFileName`)}
                    </p>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default VehicleInfo;
