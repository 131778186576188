import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import ModalLayout from "../Layout/ModalLayout";
import VehicleDetails from "../Vehicle/VehicleDetails";
import VehicleImage from "../Vehicle/VehicleImage";
import { initialVhclData } from "../Vehicle/VehicleMain";
import VehicleInfo from "../Vehicle/VehicleInfo";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  NAME_REGEX,
  REQUIRED_ERR_MSG,
  TWO_DECIMAL,
} from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";
import { permission, permissionCheck, toBase64 } from "../Helper/CommonMethods";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  APPROVE_APRVL,
  LEASEAPVL,
  REJECT_APRVL,
  VHCLLEASEFIN,
  VHCLLEASEREQ,
} from "../../ConfigConstants/ConfigConstants";

const VehicleLeaseAddEdit = ({
  modalConfig,
  handleModalClose,
  onSave,
  vhclOpts,
  avlPtr,
  fetchVhclInfos,
  vhclData,
  setVhclInfo,
  selVhclLease,
  handleOnClick,
  // isFinAllowed,
}) => {
  const userRoles = JSON.parse(getUserInfo("userRoles"));
  const [cleanUpData, setCleanUpData] = useState(new Date());
  const {
    control,
    register,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm();
  const {
    control: lControl,
    handleSubmit,
    register: lRegister,
    formState: { errors, isDirty: lIsDirty },
    reset: lReset,
    watch: lWatch,
    setError,
    clearErrors,
    setValue: lSetValue,
  } = useForm();
  const {
    fields: vhclInfoArr,
    update,
    replace,
  } = useFieldArray({
    control,
    name: "vhclInfos",
  });

  const watchRoleId = lWatch("approverRole");
  const watchVhclId = lWatch("vhclId");
  const watchApprovalId = lWatch("approvalStepId");
  const isFinAllowed =
    permission(VHCLLEASEFIN)?.allowedWrite &&
    userRoles.some((r) => r.roleId === watchRoleId);
  const watchAttachment = lWatch("attachment");
  const watchLeaseId = lWatch("id");

  useEffect(() => {
    reset(vhclData);
  }, [vhclData]);

  useEffect(() => {
    let copySelLease = Object.assign({}, selVhclLease);
    copySelLease.attachment = JSON.parse(copySelLease.attachment);
    lReset(copySelLease);
    if (copySelLease.vhclId > 0) {
      fetchVhclInfos(copySelLease.vhclId);
    }
  }, [selVhclLease]);
  const watchValidFrom = lWatch("startDate");
  const watchValidTo = lWatch("endDate");

  const onError = (err) => {
    console.error(err);
  };
  const handleAction = (argTitle, argActionCode, data) => {
    let entity = Object.assign({}, data);
    entity.title = argTitle;
    entity.approvalStatus = argActionCode;
    entity.update = lIsDirty;
    const payload = {
      leaseDto: entity,
      logDto: entity,
    };
    if (isFileAvl(data.attachment)) {
      handleOnClick(payload);
    } else {
      setError("attachment", { type: "required", message: REQUIRED_ERR_MSG });
    }
  };

  const isFileAvl = (file) => {
    let valid = true;
    if (isFinAllowed && !file.photoFileName) {
      valid = false;
    }
    return valid;
  };

  const handleRequestToLease = (data) => {
    setValue("partnerId", 0);
    data.update = lIsDirty;
    let vehData = getValues();
    vehData.update = isDirty;
    const payload = {
      vhclLease: data,
      vhclDto: vehData,
    };
    onSave(payload);
  };
  const footerbtn = (
    <div className="d-flex justify-content-center w-100 m-0">
      {watchApprovalId > 0 ? (
        <>
          {userRoles.some((r) => r.roleId === watchRoleId) &&
            permission(LEASEAPVL)?.allowedWrite && (
              <>
                <Button
                  variant="danger"
                  onClick={handleSubmit(
                    (data) =>
                      handleAction(
                        "Reject Approval Request",
                        REJECT_APRVL,
                        data
                      ),
                    onError
                  )}
                >
                  Reject
                </Button>
                <Button
                  className="theme-violet ms-1"
                  onClick={handleSubmit(
                    (data) =>
                      handleAction(
                        "Approve Approval Request",
                        APPROVE_APRVL,
                        data
                      ),
                    onError
                  )}
                >
                  Approve
                </Button>
              </>
            )}
        </>
      ) : (
        watchLeaseId === 0 &&
        permission(VHCLLEASEREQ)?.allowedWrite && (
          <Button
            variant="primary"
            className="theme-violet"
            onClick={handleSubmit(handleRequestToLease, onError)}
          >
            Request To Lease
          </Button>
        )
      )}
    </div>
  );
  const selectFile = async (key, event, code) => {
    const file = event.target.files;
    if (file.length === 0) {
      const newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };

      if (!isFinAllowed) {
        lSetValue(key, newAttachment, { shouldDirty: true });
      } else {
        lSetValue(key, newAttachment);
      }
    } else {
      clearErrors("attachment");
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }
      const attachmentName = `${code}-${moment().format("YYYYMMDDHHmmss")}`;
      const newAttachment = {
        photo: photo,
        photoFileName: attachmentName,
        photoFileType: ext,
        photoBucketUrl: "",
      };
      lSetValue(key, newAttachment, { shouldDirty: true });
    }
  };

  const loadVhclData = (opt) => {
    if (!!opt) {
      fetchVhclInfos(opt.value);
    } else {
      setVhclInfo(Object.assign({}, initialVhclData));
      setCleanUpData(new Date());
    }
  };
  const postDownload = () => {
    alert("File Downloaded Successfully!");
  };
  return (
    <ModalLayout
      moadlConfig={modalConfig}
      handleMoalClose={handleModalClose}
      footer={footerbtn}
    >
      <Row>
        <Col>
          <label className="form-label theme-label">
            Choose Vehicle <span className="text-danger">&#9733;</span>
          </label>
          <Controller
            name="vhclId"
            control={lControl}
            rules={{
              required: {
                value: true,
                message: "This field is required!!",
              },
              min: { value: 1, message: "This field is required!!" },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Select Vehicle"
                isSearchable={true}
                isClearable={true}
                className="form-control p-0"
                options={vhclOpts}
                value={
                  !!value ? vhclOpts?.find((opt) => opt.value === value) : value
                }
                // onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                onChange={(opt) => {
                  onChange(!!opt ? opt.value : 0);
                  loadVhclData(opt);
                }}
                isDisabled={+watchApprovalId > 0}
              />
            )}
          />
          {errors?.vhclId && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888; {errors?.vhclId?.message}
              </span>
            </div>
          )}
        </Col>
        <Col>
          <label className="form-label theme-label">
            Leasing To <span className="text-danger">&#9733;</span>
          </label>
          <Controller
            name="prtId"
            control={lControl}
            rules={{
              required: {
                value: true,
                message: "This field is required!!",
              },
              min: { value: 1, message: "This field is required!!" },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder="Leasing To"
                isSearchable={true}
                isClearable={true}
                className="form-control p-0"
                options={avlPtr}
                value={
                  !!value ? avlPtr?.find((opt) => opt.value === value) : value
                }
                onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                isDisabled={+watchApprovalId > 0}
              />
            )}
          />
          {errors?.prtId && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888; {errors?.prtId?.message}
              </span>
            </div>
          )}
        </Col>
      </Row>
      {+watchApprovalId > 0 && (
        <>
          <Row className="mt-1">
            <Col>
              <label className="form-label theme-label">
                Leasing Price{" "}
                {isFinAllowed && <span className="text-danger">&#9733;</span>}
              </label>
              <input
                type="number"
                className="form-control"
                name="leasingPrice"
                placeholder="Enter Amount"
                disabled={!isFinAllowed}
                {...lRegister("leasingPrice", {
                  required: {
                    value: isFinAllowed,
                    message: "This field is required",
                  },
                  pattern: {
                    value: TWO_DECIMAL,
                    message: "Enter Valid Amount!!!",
                  },
                  min: {
                    value: isFinAllowed,
                    message: "This field is required",
                  },
                })}
              />
              {errors?.leasingPrice && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.leasingPrice?.message}
                  </span>
                </div>
              )}
            </Col>
            <Col>
              <label className="form-label theme-label">
                Start Date{""}
                {isFinAllowed && <span className="text-danger">&#9733;</span>}
              </label>
              <div>
                <Controller
                  name="startDate"
                  control={lControl}
                  rules={{
                    required: {
                      value: isFinAllowed,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Start Date"
                      wrapperClassName="w-100"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={new Date()}
                      maxDate={
                        !!watchValidTo ? new Date(watchValidTo) : watchValidTo
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format(DATE_FORMAT_SERVER) : d)
                      }
                      disabled={!isFinAllowed}
                    />
                  )}
                />
                {errors?.startDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.startDate?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <label className="form-label theme-label">
                End Date{""}
                {isFinAllowed && <span className="text-danger">&#9733;</span>}
              </label>
              <div>
                <Controller
                  name="endDate"
                  control={lControl}
                  rules={{
                    required: {
                      value: isFinAllowed,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="End Date"
                      wrapperClassName="w-100"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      minDate={
                        !!watchValidFrom ? new Date(watchValidFrom) : new Date()
                      }
                      onChange={(d) =>
                        onChange(!!d ? moment(d).format("YYYY-MM-DD") : d)
                      }
                      disabled={!isFinAllowed}
                    />
                  )}
                />
                {errors?.endDate && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors?.endDate?.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col>
              <label className="form-label theme-label">
                Agreement{" "}
                {isFinAllowed && <span className="text-danger">&#9733;</span>}
              </label>
              <div>
                <div
                  className={
                    isFinAllowed
                      ? "theme-violet-txt d-flex align-items-center"
                      : "theme-gray-txt d-flex align-items-center"
                  }
                  // className="theme-violet-txt d-flex align-items-center"
                >
                  <span
                    type="button"
                    className="position-relative d-inline-block cursor-pointer"
                  >
                    <input
                      type="file"
                      className="position-absolute w-100 h-100 top-0 start-0 opacity-0 cursor-pointer"
                      aria-describedby="button-addon2"
                      accept="application/pdf, image/jpeg, image/jpg"
                      id={"attachment"}
                      onChange={(e) => selectFile("attachment", e, "AGREEMENT")}
                      title={
                        watchAttachment?.photoFileName?.length > 0
                          ? watchAttachment?.photoFileName
                          : "No File Choosen"
                      }
                      disabled={!isFinAllowed}
                    />
                    <span className="cursor-pointer">
                      {GetSvgIcon("BiFileEarmarkMed")}
                    </span>
                  </span>
                  <span
                    type="button"
                    className={
                      watchAttachment?.photoBucketUrl?.length > 0
                        ? "text-primary ps-1"
                        : "text-black ps-1"
                    }
                    disabled={watchAttachment?.photoBucketUrl?.length === 0}
                    onClick={() =>
                      DownloadFile(
                        watchAttachment?.photoBucketUrl,
                        postDownload
                      )
                    }
                    title={
                      watchAttachment?.photoBucketUrl?.length > 0
                        ? "Download - " + watchAttachment?.photoBucketUrl
                        : ""
                    }
                  >
                    {GetSvgIcon("BiArrowDown")}
                  </span>
                </div>
                <span className="text-truncate">
                  <p className="m-0">
                    {watchAttachment?.photoFileName?.length > 0 &&
                      watchAttachment?.photoFileName}
                  </p>
                </span>
              </div>
              {errors?.attachment && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors?.attachment?.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </>
      )}

      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehical Details
      </div>
      <VehicleDetails vhclData={vhclData} />
      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehical Images
      </div>
      <VehicleImage
        vhcl={vhclData}
        cleanUpData={cleanUpData}
        register={register}
        setValue={setValue}
        isReadOnly={+watchApprovalId > 0}
      />
      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehical Information
      </div>
      <VehicleInfo
        vhclData={vhclData}
        vhclInfoArr={vhclInfoArr}
        control={control}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        replace={replace}
        update={update}
        isReadOnly={+watchApprovalId > 0}
      />
    </ModalLayout>
  );
};

export default VehicleLeaseAddEdit;
