import React from "react";
import DataTable from "react-data-table-component-with-filter";
import { customDataTableStyles, defaultPageLimit, defaultRowsPerPageOptions } from "../../../Helper/Constants";
export default function ListPartnerStaff(props) {
  const { columns, staffUserList } = props
  return (
    <div>
      <div className="col-md-12 mt-3 table-responsive customized-data-table position-relative">
        <DataTable
          columns={columns}
          data={staffUserList}

          customStyles={customDataTableStyles}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </div>
  );
}
