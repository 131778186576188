import React, { useEffect, useState } from "react";
import ModalLayout from "../../Layout/ModalLayout";
import { Button } from "react-bootstrap";
import VehicleInfo from "../../Vehicle/VehicleInfo";
import VehicleImage from "../../Vehicle/VehicleImage";
import VehicleDetails from "../../Vehicle/VehicleDetails";
import { useFieldArray, useForm } from "react-hook-form";
import {
  APPROVE_APRVL,
  REJECT_APRVL,
  VHCLAPVL,
} from "../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { permission } from "../../Helper/CommonMethods";

const VehApprovalAction = ({
  modalConfig,
  handleModalClose,
  vhclInfo,
  handleOnClick,
  selApprovalEntity,
}) => {
  const userRoles = JSON.parse(getUserInfo("userRoles"));
  const [cleanUpData, setCleanUpData] = useState(new Date());
  const { register, setValue, reset, control, watch, getValues } = useForm();
  const {
    fields: vhclInfoArr,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "vhclInfos",
  });
  const handleOnClose = () => {
    setCleanUpData(new Date());
    handleModalClose();
  };

  useEffect(() => {
    reset(vhclInfo);
  }, [vhclInfo]);

  const handleAction = (argTitle, argActionCode) => {
    let entity = Object.assign({}, selApprovalEntity);
    entity.title = argTitle;
    entity.approvalStatus = argActionCode;
    handleOnClick(entity);
  };

  const footerBtns = (
    <div className="d-flex justify-content-center w-100 m-0">
      {userRoles.some(
        (role) => role.roleId === selApprovalEntity?.approverRole
      ) &&
        selApprovalEntity?.statusId !== REJECT_APRVL &&
        permission(VHCLAPVL)?.allowedWrite && (
          <>
            <Button
              variant="danger"
              onClick={() =>
                handleAction("Reject Approval Request", REJECT_APRVL)
              }
            >
              Reject
            </Button>
            <Button
              className="theme-violet ms-1"
              onClick={() =>
                handleAction("Approve Approval Request", APPROVE_APRVL)
              }
            >
              Approve
            </Button>
          </>
        )}
    </div>
  );
  return (
    <ModalLayout
      moadlConfig={modalConfig}
      handleMoalClose={handleOnClose}
      footer={footerBtns}
    >
      <div className="fw-bold  theme-violet-txt border-bottom">
        Vehical Details
      </div>
      <VehicleDetails vhclData={vhclInfo} />
      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehical Images
      </div>
      <VehicleImage
        vhcl={vhclInfo}
        cleanUpData={cleanUpData}
        register={register}
        setValue={setValue}
        isReadOnly={true}
      />
      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehical Information
      </div>
      <VehicleInfo
        vhclData={vhclInfo}
        vhclInfoArr={vhclInfoArr}
        control={control}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        replace={replace}
        update={update}
        isReadOnly={true}
      />
    </ModalLayout>
  );
};

export default VehApprovalAction;
