import { useEffect } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  BTN_TYPE_EDIT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
// import { GetBase64Img } from "../Helper/FileStoreService";
import { VEHICLE_IMAGE_CODE_MAP } from "../../ConfigConstants/ConfigConstants";
import { Tooltip } from "react-tooltip";
import { Badge } from "react-bootstrap";

const VehicleList = (props) => {
  const {
    vehicleList,
    isVhclLoaded,
    fetchVehicles,
    fetchVhclInfos,
    setVhclData,
    setBtnType,
    toggleAlert,
    handleActiveDeactive,
    setVhclId,
    downloadVhclQr,
  } = props;

  useEffect(() => {
    fetchVehicles();
  }, []);

  const handleOnClick = (data) => {
    const {
      id,
      sbuId,
      vhclTypeId,
      vhclNo,
      vhclOwner,
      assignTo,
      transferSbu,
      transferFrom,
      transferTo,
      status,
      statusVal,
    } = data;
    const vhclImgF = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };
    const vhclImgB = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };
    const vhclImgL = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgR = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgBI = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgCI = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    setVhclData({
      vhclId: id,
      vhclType: vhclTypeId,
      vhclNo: vhclNo,
      vhclOwner: vhclOwner,
      assignTo: assignTo,
      allocatedTo: sbuId,
      transferSbu: transferSbu,
      transferFrom: transferFrom,
      transferTo: transferTo,
      vhclLength: data?.vhclLength,
      bdm: data?.bdm,
      btm: data?.btm,
      tailGate: data?.tailGate,
      bondedType: data?.bondedType,
      vhclImgF: vhclImgF,
      vhclImgB: vhclImgB,
      vhclImgL: vhclImgL,
      vhclImgR: vhclImgR,
      vhclImgCI: vhclImgCI,
      vhclImgBI: vhclImgBI,
      status: status,
      statusVal: statusVal,
      vhclInfos: [],
    });
    fetchVhclInfos(id);
  };

  const getStatusBadge = (argStatusId, argStatusVal) => {
    switch (argStatusId) {
      case 852:
        return <Badge bg="success">{argStatusVal}</Badge>;
      case 855:
      case 853:
        return <Badge bg="danger">{argStatusVal}</Badge>;
      case 851:
      case 854:
        return <Badge bg="info">{argStatusVal}</Badge>;
      default:
        return <Badge bg="light">{argStatusVal}</Badge>;
    }
  };

  const columns = [
    {
      name: "Vehicle Type",
      selector: (row) => row.vhclType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vhclNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Owner",
      selector: (row) => row.vhclOwner,
      sortable: true,
      filterable: true,
    },
    {
      name: "Allocated To",
      selector: (row) => row.sbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Transfer SBU",
      selector: (row) => row.transferSbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.statusVal,
      filterable: true,
      cell: (row) => <>{getStatusBadge(row?.status, row?.statusVal)}</>,
      // width: "8%",
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "YES" : "NO"),
      filterable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
          },
        },
      ],
      // width: "8%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-bs-toggle="modal"
            data-bs-target="#vehicle-add-edit-modal"
            data-tooltip-id={"edit-" + row.id}
            onClick={() => {
              setBtnType(BTN_TYPE_EDIT);
              handleOnClick(row);
            }}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.id} content={"Edit"} place="left" />
          <div
            data-tooltip-id={"vhcl-" + row.id}
            onClick={() => {
              handleActiveDeactive(row.active, row.vhclNo);
              setVhclId(row.id);
              toggleAlert(true);
            }}
            className="ps-2"
          >
            {row.active === true
              ? GetSvgIcon("BixCircle")
              : GetSvgIcon("BiCheck2Circle")}
          </div>
          <Tooltip
            id={"vhcl-" + row.id}
            content={
              row.active === true
                ? "Deactivate " + row.vhclNo
                : "Activate " + row.vhclNo
            }
            place="left"
          />
          <div
            className="theme-violet-txt ps-2"
            onClick={() => {
              downloadVhclQr(row);
            }}
            data-tooltip-id={"qr-" + row.id}
          >
            {GetSvgIcon("Qrcode")}
          </div>
          <Tooltip id={"qr-" + row.id} content={"Download QR"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "80px",
    },
  ];

  return (
    <div>
      <div className="col-md-12 customized-data-table position-relative">
        {isVhclLoaded ? (
          <DataTable
            columns={columns}
            data={vehicleList}
            pagination
            striped
            highlightOnHover
            customStyles={customDataTableStyles}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        ) : (
          <div id="noRecId">
            <span>
              <center>
                <b>No Record Found</b>
              </center>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleList;
