import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { coalesce, permission, permissionCheck } from "../Helper/CommonMethods";
import {
  APPROVE_APRVL,
  LEASETERMINATION,
} from "../../ConfigConstants/ConfigConstants";

const VehicleLeaseList = (props) => {
  const { tableData, handleClipboardClick, handleTerminnationClick } = props;

  const columns = [
    {
      name: "Vehicle No",
      selector: (row) => row.vehicleNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner",
      selector: (row) => row.partner,
      sortable: true,
      filterable: true,
    },
    {
      name: "Price",
      selector: (row) => row.leasingPrice,
      filterable: true,
      sortable: true,
    },
    {
      name: "Open From",
      selector: (row) =>
        !!row.startDate &&
        moment(row.startDate, "YYYY-MM-DD", true).format(DATE_FORMAT_MOMENT),
      filterable: true,
      sortable: true,
    },
    {
      name: "Ends On",
      selector: (row) =>
        (!!row.endDate || !!row.terminatedOn) &&
        moment(
          coalesce(row.terminatedOn, row.endDate),
          "YYYY-MM-DD",
          true
        ).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: <div className="text-wrap">Termination Reason</div>,
      selector: (row) => row?.terminationReason,
      sortable: true,
      filterable: true,
    },
    {
      name: "Terminated By",
      selector: (row) => row?.terminateUser,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
    },
    {
      name: "Active",
      selector: (row) => (row.isActive ? "Yes" : "No"),
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"Edit-" + row.id}
            onClick={() => handleClipboardClick(row)}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"Edit-" + row.id} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "40px",
    },
    {
      name: "",
      cell: (row) =>
        moment().isBefore(row?.isActive) &&
        moment().isBefore(row?.endDate) &&
        row?.statusId === APPROVE_APRVL && (
          <>
            <div
              data-tooltip-id={"terminate-" + row.id}
              onClick={() => handleTerminnationClick(row)}
            >
              {GetSvgIcon("TerminationIcon")}
            </div>
            <Tooltip
              id={"terminate-" + row.id}
              content={"Terminate"}
              place="left"
            />
          </>
        ),
      allowOverflow: true,
      button: true,
      omit: !permission(LEASETERMINATION)?.allowedWrite,
      width: "40px",
    },
  ];
  return (
    <div className="col-md-12 customized-data-table position-relative">
      {
        <DataTable
          columns={columns}
          data={tableData}
          noDataComponent="There are no vehicle lease info found!!"
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      }
    </div>
  );
};

export default VehicleLeaseList;
