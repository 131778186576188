import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toBase64 } from "../Helper/CommonMethods";
import {
  ALPHANUM,
  ALPHANUM_NAME,
  BTN_TYPE_ADD,
  maxVhclSizeForHQ,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  TWO_DECIMAL,
  UPLOAD_IMG_B_TEXT,
  UPLOAD_IMG_BI_TEXT,
  UPLOAD_IMG_CI_TEXT,
  UPLOAD_IMG_F_TEXT,
  UPLOAD_IMG_L_TEXT,
  UPLOAD_IMG_R_TEXT,
} from "../Helper/Constants";
import { GetBase64Img } from "../Helper/FileStoreService";
import { LINEHAUL_PARTNER_COMPANY_USER_TYPE } from "../../ConfigConstants/ConfigConstants";
import { Alert } from "react-bootstrap";
import Select from "react-select";
import VehicleImage from "./VehicleImage";
import VehicleInfo from "./VehicleInfo";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgCI: "",
  vhclImgBI: "",
};
const initLabelData = {
  vhclImgF: UPLOAD_IMG_F_TEXT,
  vhclImgB: UPLOAD_IMG_B_TEXT,
  vhclImgL: UPLOAD_IMG_L_TEXT,
  vhclImgR: UPLOAD_IMG_R_TEXT,
  vhclImgCI: UPLOAD_IMG_CI_TEXT,
  vhclImgBI: UPLOAD_IMG_BI_TEXT,
};

const tailGateOpts = [
  { label: "YES", value: true },
  { label: "NO", value: false },
];

const VehicleAddEdit = (props) => {
  let {
    vhclInfos,
    vhclData,
    hq,
    alloctatedTo,
    vhclTypes,
    clearData,
    saveVhclData,
    btnType,
    inlineAlert,
    inlineAlertMsg,
    alertVariant,
    dropdownData,
  } = props;
  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;
  let { allocatedTo, transferSbu } = vhclData;

  const [labelName, setLabelName] = useState(
    JSON.parse(JSON.stringify(initLabelData))
  );
  const [isDisabled, setDisabled] = useState({});
  const [cleanUpData, setCleanUpData] = useState(new Date());
  const [imgData, setImgData] = useState(
    JSON.parse(JSON.stringify(initImgData))
  );

  let primarySbu = allocatedTo ? allocatedTo : hq.id;

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: vhclData,
  });

  const {
    fields: vhclInfoArr,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "vhclInfos",
  });

  useEffect(() => {
    vhclData.vhclInfos = vhclInfos;
    reset(vhclData);
    // vhclInfos.map((info, index) => {
    //   labelName[`vhclInfos.${index}.attachment1`] =
    //     info.attachment1.photoFileName !== ""
    //       ? info.attachment1.photoFileName
    //       : "";
    //   isDisabled[`vhclInfos.${index}.attachment1`] =
    //     info.attachment1.photoFileName === "" ? true : false;
    //   return info;
    // });
    // JSON.stringify(watch("vhclInfos"));
  }, [vhclData, vhclInfos]);

  useEffect(() => {
    downloadImg(vhclData);
  }, [vhclData]);

  const transferFromWatch = watch("transferFrom");
  const transferToWatch = watch("transferTo");
  const transferSbuWatch = watch("transferSbu");
  const watchStatus = watch("status");
  const BLOCK_EDIT_STATUS_ARR = [851, 854];

  const setAllocatedTo = (event) => {
    clearErrors("vhclType");
    setValue(event.target.id, event.target.value);

    const selectedVhclType = vhclTypes.filter((type) => {
      return type.id === parseInt(event.target.value);
    });
    let hqId = hq.id;
    console.log(hqId);
    if (selectedVhclType[0].vhclSize > maxVhclSizeForHQ) {
      setValue("allocatedTo", hqId);
    }
  };

  const GetImageFromCloud = (vhcl, key, DEFAULT_IMG_TEXT) => {
    GetBase64Img(vhcl[key]?.photoFileName).then((resp) => {
      if (resp === "") {
        labelName[key] = DEFAULT_IMG_TEXT;
      } else {
        labelName[key] = vhcl[key]?.photoFileName;
      }
      setImgData((img) => ({
        ...img,
        [key]: resp,
      }));
    });
  };

  const downloadImg = (vhcl) => {
    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgF", UPLOAD_IMG_F_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgB", UPLOAD_IMG_B_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgL", UPLOAD_IMG_L_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgR", UPLOAD_IMG_R_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgCI", UPLOAD_IMG_CI_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgBI", UPLOAD_IMG_BI_TEXT);
  };

  const clear = () => {
    clearErrors();
    clearData();
    reset();
    // setImgData(JSON.parse(JSON.stringify(initImgData)));
    // setLabelName(JSON.parse(JSON.stringify(initLabelData)));
    setCleanUpData(new Date());
  };

  const save = (data) => {
    // clear()
    saveVhclData(data);
  };
  const onErrors = (data) => {
    console.log(getValues());
    console.error(data);
  };

  const isValidTransferData = () => {
    if (transferFromWatch && transferToWatch && transferSbuWatch) {
      return true;
    } else if (!transferFromWatch && !transferToWatch && !transferSbuWatch) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="modal fade"
      id="vehicle-add-edit-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Vehicle Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => clear()}
            ></button>
          </div>
          {inlineAlert && (
            <div className="mt-2">
              <Alert key={alertVariant} variant={alertVariant}>
                {inlineAlertMsg}
              </Alert>
            </div>
          )}
          {BLOCK_EDIT_STATUS_ARR.includes(watchStatus) && (
            <div className="mt-2 mx-4">
              <Alert variant="warning" dismissible>
                This vehicle is under approval. You are not allowed to update
                any data.
              </Alert>
            </div>
          )}
          <div className="modal-body">
            <div className="row">
              <div className="w-100">
                <div className="row">
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Vehicle Type <span className="text-danger">&#9733;</span>
                    </label>
                    <select
                      id="vhclType"
                      className="form-select"
                      aria-label="Default select example"
                      name="vhclType"
                      {...register("vhclType", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                      })}
                      onChange={(event) => setAllocatedTo(event)}
                    >
                      <option value="">-Select-</option>
                      {vhclTypes.map((data, index) => {
                        const { id, vhclType } = data;
                        if (vhclType === id) {
                          return (
                            <option key={index} value={id} selected>
                              {vhclType}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={id}>
                              {vhclType}
                            </option>
                          );
                        }
                      })}
                    </select>

                    {errors.vhclType && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.vhclType.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Vehicle No<span className="text-danger">&#9733;</span>
                    </label>

                    <input
                      id="vhclNo"
                      type="text"
                      className="form-control"
                      name="vhclNo"
                      {...register("vhclNo", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM,
                          message: PATTERN_ERR_MSG,
                        },
                        maxLength: {
                          value: 20,
                          message: "Field should contain 20 characters!!!",
                        },
                      })}
                    />
                    {errors.vhclNo && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.vhclNo.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Owner<span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      id="vhclOwner"
                      type="text"
                      className="form-control"
                      name="vhclOwner"
                      {...register("vhclOwner", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_NAME,
                          message: PATTERN_ERR_MSG,
                        },
                        maxLength: {
                          value: 50,
                          message: "Field should contain 50 characters!!!",
                        },
                      })}
                    />
                    {errors.vhclOwner && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.vhclOwner.message}
                        </span>
                      </div>
                    )}
                  </div>
                  {userProfileId === 0 && (
                    <div className="col-md-3 mb-2">
                      <label
                        htmlFor="floatingInputGrid"
                        className="form-label theme-label"
                      >
                        Assign To<span className="text-danger">&#9733;</span>
                      </label>
                      <Controller
                        name="assignTo"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          min: {
                            value: 1,
                            message: REQUIRED_ERR_MSG,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            placeholder="Select Assignee"
                            isSearchable={true}
                            isClearable={true}
                            className="form-control p-0"
                            options={dropdownData?.vhclOwnerOpts}
                            value={
                              !!value
                                ? dropdownData?.vhclOwnerOpts?.filter(
                                    (vc) => vc.value === value
                                  )
                                : value
                            }
                            onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                          />
                        )}
                      />
                      {errors.assignTo && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.assignTo.message}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Primary SBU<span className="text-danger">&#9733;</span>
                    </label>
                    <select
                      id="allocatedTo"
                      className="form-select"
                      aria-label="Default select example"
                      name="allocatedTo"
                      {...register("allocatedTo", {
                        required: { value: true, message: REQUIRED_ERR_MSG },
                        validate: (v) =>
                          v === 0 || v === "" ? REQUIRED_ERR_MSG : true,
                      })}
                    >
                      <option value="">-Select-</option>
                      {alloctatedTo.map((hub, index) => {
                        const { id, code } = hub;
                        if (primarySbu === id) {
                          return (
                            <option key={index} value={id} selected>
                              {code}
                            </option>
                          );
                        } else {
                          return (
                            <option key={index} value={id}>
                              {code}
                            </option>
                          );
                        }
                      })}
                    </select>
                    {errors.allocatedTo && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.allocatedTo.message}
                        </span>
                      </div>
                    )}
                  </div>
                  {/*------------ New Implementation------------*/}
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Vehicle Length{" "}
                      <span className="text-danger">&#9733;</span>(ft)
                    </label>
                    <input
                      id="vhclLength"
                      type="number"
                      className="form-control"
                      name="vhclLength"
                      {...register("vhclLength", {
                        required: {
                          value: true,
                          message: "This Field is Required!!!",
                        },
                        pattern: {
                          value: TWO_DECIMAL,
                          message: "Please check format: 999999999.99",
                        },
                        min: {
                          value: 1,
                          message: "Please add a valid length!!!",
                        },
                      })}
                    />
                    {errors.vhclLength && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.vhclLength.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      BDM <span className="text-danger">&#9733;</span>(kg)
                    </label>
                    <input
                      id="bdm"
                      type="numeric"
                      className="form-control"
                      name="bdm"
                      {...register("bdm", {
                        required: {
                          value: true,
                          message: "This Field is Required!!!",
                        },
                        pattern: {
                          value: TWO_DECIMAL,
                          message: "Please check format: 999999999.99",
                        },
                        min: {
                          value: 1,
                          message: "Please add a valid weight!!!",
                        },
                      })}
                    />
                    {errors.bdm && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.bdm.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      BTM <span className="text-danger">&#9733;</span>(kg)
                    </label>
                    <input
                      id="btm"
                      type="text"
                      className="form-control"
                      name="btm"
                      {...register("btm", {
                        required: {
                          value: true,
                          message: "This Field is Required!!!",
                        },
                        pattern: {
                          value: TWO_DECIMAL,
                          message: "Please check format: 999999999.99",
                        },
                        min: {
                          value: 1,
                          message: "Please add a valid weight!!!",
                        },
                      })}
                    />
                    {errors.btm && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.btm.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      Tail Gate<span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="tailGate"
                      control={control}
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: REQUIRED_ERR_MSG,
                      //   },
                      // }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Select Tail Gate"
                          isSearchable={true}
                          isClearable={true}
                          className="form-control p-0"
                          options={tailGateOpts}
                          value={tailGateOpts?.filter(
                            (tg) => Boolean(tg.value) === Boolean(value)
                          )}
                          onChange={(opt) => onChange(!!opt ? opt.value : null)}
                        />
                      )}
                    />
                    {errors.tailGate && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.tailGate.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor="floatingInputGrid"
                      className="form-label theme-label"
                    >
                      BONDED TYPE<span className="text-danger">&#9733;</span>
                    </label>
                    <Controller
                      name="bondedType"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: "This Field is Required!!!",
                        },
                        min: {
                          value: 1,
                          message: REQUIRED_ERR_MSG,
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder="Select Bonded Type"
                          isSearchable={true}
                          isClearable={true}
                          className="form-control p-0"
                          options={dropdownData?.bondedTypeOpts}
                          value={
                            !!value
                              ? dropdownData?.bondedTypeOpts.filter(
                                  (bt) => bt.value === value
                                )
                              : value
                          }
                          onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                        />
                      )}
                    />
                    {errors.bondedType && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.bondedType.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="clearfix"></div>
                  <VehicleImage
                    vhcl={vhclData}
                    register={register}
                    setValue={setValue}
                    cleanUpData={cleanUpData}
                    isReadOnly={false}
                  />
                </div>

                <div className="col-lg-12">
                  <div className="fw-bold my-2 theme-violet-txt border-bottom">
                    Vehical Information
                  </div>
                  <VehicleInfo
                    vhclData={vhclData}
                    vhclInfoArr={vhclInfoArr}
                    control={control}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    replace={replace}
                    update={update}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            {btnType === BTN_TYPE_ADD ? (
              <button
                type="button"
                className="btn btn-default border"
                onClick={() => clear()}
              >
                Clear
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-default border"
                data-bs-dismiss="modal"
                onClick={() => clear()}
              >
                Cancel
              </button>
            )}

            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(save, onErrors)}
              disabled={BLOCK_EDIT_STATUS_ARR.includes(watchStatus)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleAddEdit;
