import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { REQUIRED_ERR_MSG } from "../../Helper/Constants";
import Select from "react-select";
import "../../OrderBooking/Track/TrackWaybill.css";
import { useEffect } from "react";

const SchedulePlan = ({ handleClose, modalConfig, dropdownOpts, onSave }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({});

  const { fields: itinerary } = useFieldArray({
    control,
    name: "itinerary",
  });

  const watchDr1 = watch("driver1Id");
  const watchDr2 = watch("driver2Id");
  const watchPlanSts = watch("planSts");

  useEffect(() => {
    reset(modalConfig?.data);
  }, [modalConfig]);

  const renderTimeline = (item, outIndex) => {
    const eta = item?.etaT?.split(" ");
    const etd = item?.etdT?.split(" ");
    const ata = item?.ataT?.split(" ");
    const atd = item?.atdT?.split(" ");
    return (
      <>
        <li key={outIndex} className={"tl-item reached margin-left-custom"}>
          <div className="timestamp left-70">
            <div className="text-nowrap">
              <span className="fw-bold theme-blue-txt text-uppercase">
                Arrival/Departure
              </span>
            </div>
            <div className="d-flex w-100">
              <span
                className="fw-bold text-secondary me-2 text-nowrap"
                title="ETA"
              >
                {!!item.etaT ? (
                  <>
                    {eta[0]} <br /> {eta[1]}
                  </>
                ) : (
                  <>&#8212; </>
                )}
              </span>
              <span
                className="fw-bold text-secondary ms-4 text-nowrap"
                title="ETD"
              >
                {!!item.etdT ? (
                  <>
                    {etd[0]} <br /> {etd[1]}
                  </>
                ) : (
                  <>&#8212; </>
                )}
              </span>
            </div>
            <div className="d-flex w-100 mt-2">
              <span
                className="fw-bold text-primary me-2 text-nowrap"
                title="ATA"
              >
                {!!item.ataT ? (
                  <>
                    {ata[0]} <br /> {ata[1]}
                  </>
                ) : (
                  <>&#8212; </>
                )}
              </span>
              <span
                className="fw-bold text-primary ms-4 text-nowrap"
                title="ATD"
              >
                {!!item.atdT ? (
                  <>
                    {atd[0]} <br /> {atd[1]}
                  </>
                ) : (
                  <>&#8212; </>
                )}
              </span>
            </div>
          </div>
          <div className="float-start">
            <div className="font-12 text-secondary w-100">
              {item?.stopSbuCode}
            </div>
          </div>
          {/* <div className="position-absolute start-0 d-flex v-place font-12 fw-bold">
            <div className="mx-3 theme-blue-txt align-self-center text-primary text-nowrap">
              {GetSvgIcon("BiCursor")} routeCode
            </div>
          </div> */}
        </li>
      </>
    );
  };

  const renderLabel = (stopSeq) => {
    switch (stopSeq) {
      case 0:
        return "Origin";
      case 99:
        return "Destination";
      default:
        return `Stop${stopSeq}`;
    }
  };

  const onHide = () => {
    handleClose();
  };

  const onError = (err) => {
    console.error(err);
  };

  return (
    <>
      <Modal
        show={modalConfig.show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        fullscreen={modalConfig.fullscreen}
      >
        <Modal.Header closeButton className="theme-header">
          <Modal.Title>CHARTERED PLAN DETAILS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="ms-1 w-100">
            <Col
              md={6}
              className="shadow-lg border border-1 rounded-3 id-cards m-0"
            >
              <Row>
                <Col md={4}>
                  <label className="form-label theme-blue-txt">
                    Route Code
                  </label>
                  <div className="grd-bg p-2 rounded-3">
                    {watch("routeCode")}
                  </div>
                </Col>
                {modalConfig?.modalType === "E" && (
                  <>
                    <Col md={4}>
                      <label className="form-label theme-blue-txt">
                        Trip Start
                      </label>
                      <div className="grd-bg p-2 rounded-3">
                        {watch("tripStart")}
                      </div>
                    </Col>
                    <Col md={4}>
                      <label className="form-label theme-blue-txt">
                        Status
                      </label>
                      <div className="grd-bg p-2 rounded-3">
                        {watch("status")}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col md={4}>
                  <label className="form-label theme-blue-txt">
                    Vehicle <span className="text-danger">&#9733;</span>
                  </label>
                  {watchPlanSts === 572 || modalConfig?.modalType === "A" ? (
                    <Controller
                      name={"truckId"}
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        min: { value: 1, message: REQUIRED_ERR_MSG },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={dropdownOpts?.vhclOpts}
                          value={dropdownOpts?.vhclOpts.find(
                            (el) => el.value === value
                          )}
                          onChange={(opt) =>
                            !!opt ? onChange(opt.value) : onChange(0)
                          }
                          placeholder="Select Vehicle"
                          isSearchable={true}
                          isClearable={true}
                          className="form-control p-0"
                        />
                      )}
                    />
                  ) : (
                    <div className="grd-bg p-2 rounded-3">
                      {
                        dropdownOpts?.vhclOpts?.find(
                          (opt) => opt.value === getValues("truckId")
                        )?.label
                      }
                    </div>
                  )}

                  {errors?.truckId && (
                    <div>
                      <span className="badge bg-danger">
                        &#9888; {errors?.truckId?.message}
                      </span>
                    </div>
                  )}
                </Col>

                <Col md={4}>
                  <label className="form-label theme-blue-txt">
                    Driver 1 <span className="text-danger">&#9733;</span>
                  </label>
                  <div>
                    {watchPlanSts === 572 || modalConfig?.modalType === "A" ? (
                      <Controller
                        name={"driver1Id"}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          min: { value: 1, message: REQUIRED_ERR_MSG },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={dropdownOpts?.driverOpts}
                            value={dropdownOpts?.driverOpts.find(
                              (el) => el.value === value
                            )}
                            onChange={(opt) =>
                              !!opt ? onChange(opt.value) : onChange(0)
                            }
                            placeholder="Select Driver"
                            isSearchable={true}
                            isClearable={true}
                            className="form-control p-0"
                            isOptionDisabled={(opt) => opt.value === +watchDr2}
                          />
                        )}
                      />
                    ) : (
                      <div className="grd-bg p-2 rounded-3">
                        {
                          dropdownOpts?.driverOpts?.find(
                            (opt) => opt.value === getValues("driver1Id")
                          )?.label
                        }
                      </div>
                    )}
                    <div>
                      {errors.driver1Id && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.driver1Id.message}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md={4}>
                  <label className="form-label theme-blue-txt">Driver 2</label>
                  {watchPlanSts === 572 || modalConfig?.modalType === "A" ? (
                    <Controller
                      name={"driver2Id"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={dropdownOpts?.driverOpts}
                          value={dropdownOpts?.driverOpts.find(
                            (el) => el.value === value
                          )}
                          onChange={(opt) =>
                            !!opt ? onChange(opt.value) : onChange(0)
                          }
                          placeholder="Select Driver"
                          isSearchable={true}
                          isClearable={true}
                          className="form-control p-0"
                          isOptionDisabled={(option) =>
                            option.value === +watchDr1
                          }
                        />
                      )}
                    />
                  ) : (
                    <div className="grd-bg p-2 rounded-3">
                      {
                        dropdownOpts?.driverOpts?.find(
                          (opt) => opt.value === getValues("driver2Id")
                        )?.label
                      }
                    </div>
                  )}
                </Col>
              </Row>
              {!!getValues("rRemarks") && (
                <Row>
                  <Col>
                    <label className="form-label theme-blue-txt">Remarks</label>
                    <div className="font-18 grd-bg p-2 rounded-3">
                      {getValues("rRemarks")}
                    </div>
                  </Col>
                </Row>
              )}

              {itinerary.map((item, index) => (
                <Row>
                  <Col md={4}>
                    <label className="form-label theme-blue-txt">
                      {renderLabel(item?.stopSeq)}
                    </label>
                    <div className="font-12 grd-bg p-2 rounded-3">
                      {item.stopSbuCode}
                    </div>
                  </Col>
                  <Col md={4}>
                    <label className="form-label theme-blue-txt">
                      Estimated Arrival{" "}
                      {item?.stopSeq > 0 && (
                        <span className="text-danger">&#9733;</span>
                      )}
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register(`itinerary.${index}.sta`, {
                        required: {
                          value: item?.stopSeq > 0,
                          message: "This Field is Required",
                        },
                      })}
                    />
                    {item?.stopSeq > 0 && errors.itinerary?.[index]?.sta && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.itinerary[index].sta.message}{" "}
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col md={4}>
                    <label className="form-label theme-blue-txt">
                      Estimated Departure
                      {item?.stopSeq < 99 && (
                        <span className="text-danger">&#9733;</span>
                      )}
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register(`itinerary.${index}.std`, {
                        required: {
                          value: item?.stopSeq < 99,
                          message: "This Field is Required",
                        },
                      })}
                    />
                    {item?.stopSeq < 99 && errors.itinerary?.[index]?.std && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.itinerary[index].std.message}{" "}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
              ))}
              <Row className="mt-2">
                <Col className="text-center">
                  {(watchPlanSts === 572 || modalConfig?.modalType === "A") && (
                    <Button
                      onClick={handleSubmit(onSave, onError)}
                      disabled={!isDirty}
                      className="theme-violet"
                    >
                      SAVE
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div className="shadow border border-1 rounded-3 end-0 h-100 overflow-auto">
                <div className=" pb-5 track-shipment-bg">
                  <div className="history-tl-container">
                    {modalConfig?.modalType === "E" ? (
                      <ul className="tl">
                        {itinerary.map((item, index) =>
                          renderTimeline(item, index)
                        )}
                      </ul>
                    ) : (
                      <ul className="tl"></ul>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SchedulePlan;
