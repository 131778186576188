import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { initValPartnerPrf } from "../../ApprovalWorkFlow/Partner/initValPartnerPrf";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  getFileName,
  toBase64,
  approvalHistory,
  permission,
} from "../../Helper/CommonMethods";
import Select from "react-select";
import {
  ALPHANUM,
  ALPHANUM_NAME,
  COUNTRY_CODES,
  DATE_FORMAT_CLIENT,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  PHONE_NUM,
  SERVER_ERR_MSG,
} from "../../Helper/Constants";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import { DownloadFile, GetBase64Img } from "../../Helper/FileStoreService";
import ApprovalLogList from "../../ApprovalWorkFlow/ApprovalLogList";
import { APVLLOG } from "../../../ConfigConstants/ConfigConstants";
const initialVhclType = {
  id: 0,
  vhclType: "",
  vhclSize: 0,
  maxWeight: 0,
  maxPallets: 0,
  palletHeight: 0,
  palletWeight: 0,
  maxPalletWeight: 0,
};

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgI: "",
};
const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
  photoFileName: "",
};

export const NUMBER_REGEX = /^[0-9]*$/;

export default function DriverProfile() {
  const [driverProfile, setDriverProfile] = useState(
    JSON.parse(JSON.stringify(initValPartnerPrf))
  );
  const [vhclTypes, setVhclTypes] = useState([initialVhclType]);
  const [reload, setReload] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [isUserActive, setIsUserActive] = useState(false);
  const [show, setShow] = useState(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const [imgData, setImgData] = useState(
    JSON.parse(JSON.stringify(initImgData))
  );
  const [userType, setUserType] = useState();
  const modalRef = useRef();
  const callback = () => {
    setReload((reload) => reload + 1);
  };
  const [isDisabled, setDisabled] = useState({});
  const [photoImg, setPhotoImg] = useState();
  const [photoFile, setPhotoFile] = useState(initPhotoFile);
  const [imageData, setImageData] = useState(initPhotoFile);
  const navigate = useNavigate();
  useEffect(() => {
    const userType = getUserInfo("userType");
    setIsUserActive(getUserInfo("userActive"));
    fetchDriverDetails(userType);
    loadVhclTypes();
    setUserType(userType);
  }, [reload]);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: driverProfile,
  });
  const { fields: userInfos } = useFieldArray({
    control,
    name: "userInfoList",
  });
  const { fields: vhclList } = useFieldArray({
    control,
    name: "vehicleList",
  });

  const downloadVehicleimage = async (vehicle) => {
    GetBase64Img(vehicle?.vhclImgF?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgF: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgB?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgB: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgL?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgL: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgR?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgR: resp,
      }));
    });
    GetBase64Img(vehicle?.vhclImgI?.photoFileName).then((resp) => {
      setImgData((img) => ({
        ...img,
        vhclImgI: resp,
      }));
    });
  };

  const fetchDriverDetails = async (userType) => {
    let uri = `GetPartnerProfile?profileId=${getUserInfo(
      "acctId"
    )}&roleId=0&userType=${userType}`;
    setShow(true);
    let data = await CallApiGet(uri);
    setShow(false);
    if (data.respCode === 200 || data.respCode === 0) {
      setDriverProfile(data.respData);
      let partnerPrflDetails = data.respData;

      reset(partnerPrflDetails);
      setValue("phoneCode", partnerPrflDetails.phone.substr(0, 3));
      setValue("phone", partnerPrflDetails.phone.substr(3));
      downloadVehicleimage(partnerPrflDetails.vehicleList[0]);
    } else {
    }
  };
  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;

    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };

  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");
    if (data.respCode === 200 || data.respCode === 0) {
      let tempArr = [];
      const vhclTypesArr = data.respData;
      vhclTypesArr.forEach((element) => {
        tempArr.push({
          label: element.vhclType,
          value: element.id,
        });
      });
      setVhclTypes(tempArr);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      // onError()
    }
  };

  const onSubmit = async (data) => {
    setShow(true);
    if (data.phone !== "") {
      data.phone = data.phoneCode + data.phone;
    }
    delete data.phoneCode;
    data?.userInfoList?.forEach((e)=>{
      if(e.infoCode===125)
      {
        if(data?.profilePhoto?.photo?.length>0)
        {
              e.infoAttachment=data.profilePhoto
         }
      }
    })
    const uri = `savePartnerProfile?userType=${userType}`;
    let resp = await CallApiPost(uri, data);
    setShow(false);
    if (resp.respCode === 200 || resp.respCode === 0) {
      onSuccess("Profile details updated successfully !!!");
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the write permission!!");
      modalRef.current.click();
    } else {
      onFailure();
    }

    console.log(data);
  };
  const onSuccess = (sucMsg) => {
    setIsSuccess(true);
    setTitle("Congratulations");
    setDescription(sucMsg);
    modalRef.current.click();
  };

  const onFailure = () => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(SERVER_ERR_MSG);
    modalRef.current.click();
  };
  const onError = (error) => {
    console.log(error);
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(
      driverProfile.partnerId,
      driverProfile.approvalUserTypeId
    );
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };

  const vhclPhotoFPlaceHolder = watch(
    "vehiclePhotoFront.photoFileName",
    "Upload Truck Front Photo"
  );
  const vhclPhotoRPlaceHolder = watch(
    "vehiclePhotoRight.photoFileName",
    "Upload Truck Right Photo"
  );
  const vhclPhotoLPlaceHolder = watch(
    "vehiclePhotoLeft.photoFileName",
    "Upload Truck Left Photo"
  );
  const vhclPhotoBPlaceHolder = watch(
    "vehiclePhotoBack.photoFileName",
    "Upload Truck Back Photo"
  );
  const vhclPhotoIPlaceHolder = watch(
    "vehiclePhotoInternal.photoFileName",
    "Upload Truck Internal Photo"
  );
  const sucs = () => {};
  const download = (fileName) => {
    if (fileName !== "") {
      setShow(true);
      DownloadFile(fileName, sucs, sucs);
      setShow(false);
    }
  };
  const selectPhotoFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
    } else {
      var fileName = file[0].name;
      setPhotoImg(file[0]);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }

      const newAttachment = {
        photo: photo,
        photoFileType: ext,
        photoBucketUrl: "",
        photoFileName: fileName,
      };
      setPhotoFile(newAttachment);
      setValue("profilePhoto", newAttachment);
      // clearErrors("profileDoc");
      // setPhotoImgErrMsg();
    }
  };
  useEffect(()=>{
    let profileUrl=driverProfile?.userInfoList.find((e)=>e.infoCode === 125)?.infoAttachment?.photoBucketUrl
    if (profileUrl && profileUrl !== "") {
      setImageData(profileUrl);
  } else {
    setImageData("");
  }
  },[driverProfile])
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="mt-2">
              <div className="col-md-2 d-none">
                <nav className="nav flex-column profile-action" id="nav-tab">
                  <button
                    className="nav-link active"
                    id="nav-RegisteredCustomer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-RegisteredCustomer"
                    type="button"
                    role="tab"
                    aria-controls="nav-RegisteredCustomer"
                    aria-selected="true"
                  >
                    Personal Details
                  </button>
                  <button
                    className="nav-link"
                    id="nav-ISR-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-ISR"
                    type="button"
                    role="tab"
                    aria-controls="nav-ISR"
                    aria-selected="false"
                  >
                    Company Details
                  </button>
                  <button
                    className="nav-link"
                    id="nav-Finance-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-Finance"
                    type="button"
                    role="tab"
                    aria-controls="nav-Finance"
                    aria-selected="false"
                  >
                    Bank Details
                  </button>
                  <button
                    className="nav-link"
                    id="way-bill-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#way-bill-details"
                    type="button"
                    role="tab"
                    aria-controls="way-bill-details"
                    aria-selected="false"
                  >
                    Waybill Details
                  </button>
                  <button
                    className="nav-link"
                    id="pickup-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#pickup-details"
                    type="button"
                    role="tab"
                    aria-controls="pickup-details"
                    aria-selected="false"
                  >
                    Pickup Details
                  </button>
                  <button
                    className="nav-link"
                    id="password-details-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#password-details"
                    type="button"
                    role="tab"
                    aria-controls="password-details"
                    aria-selected="false"
                  >
                    Password Details
                  </button>
                </nav>
              </div>
              <div className="col-md-12 ">
                <div className="alert alert-info">
                  <div>
                    <div className="float-start">
                      <h5 className="card-title">Current Approval Status:</h5>
                      <p className="card-text">{driverProfile.approveStatus}</p>
                    </div>
                    {permission(APVLLOG).allowedWrite && (
                      <button
                        type="button"
                        className="btn btn-outline-secondary theme-violet me-1 float-end"
                        onClick={getApprovalHistory}
                        data-bs-toggle="modal"
                        data-bs-target="#approval-history-modal"
                      >
                        Approval History
                      </button>
                    )}
                    <div className="clearfix"></div>
                  </div>
                </div>
                <div className="row" id="nav-tabContent">
                  <div className="col-md-8 mb-4">
                    <div
                      className="tab-pane d-block show border border-2 p-2"
                      id="nav-RegisteredCustomer"
                      role="tabpanel"
                      aria-labelledby="nav-RegisteredCustomer-tab"
                    >
                      <h5 className="theme-violet-txt">Personal Details</h5>
                      <div className="row">
                      <div className="col-md-4 mb-2">
                        <input type="hidden" {...register("partnerId")} /> 
                        <div 
                      className="mb-1 file-placeholder thumbnail-pic d-flex justify-content-center"
                      {...register("profilePhoto")}
                    > 
                        <input
                          type="file"
                          accept="image/png, image/jpeg "
                          onChange={selectPhotoFile}
                        />
                      {photoImg ? (
                        <img
                          src={URL.createObjectURL(photoImg)}
                          alt="img"
                        />
                     ) : imageData !== "" ? (
                        <img
                        src={
                          imageData !== ""
                            ?  imageData
                            : ""
                        }
                          alt="img"
                        />
                       ) : ( 
                        <div>
                          Upload Photo{" "}
                        </div>
                       )} 
                    </div>
                    </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Driver Name
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="driverName"
                            defaultValue={driverProfile.driverName}
                            {...register("driverName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxLength="200"
                          />
                          {errors.driverName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.driverName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Driver address{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={driverProfile.address}
                            {...register("address", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxlength="200"
                          />
                          {errors.address && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.address.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Email <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={driverProfile.email}
                            {...register("email")}
                            maxlength="100"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Phone <span className="text-danger">&#9733;</span>
                          </label>
                          <div className="input-group">
                            <select
                              name="phoneCode"
                              {...register("phoneCode")}
                              defaultValue="+60"
                              className="form-control country-code-customize"
                            >
                              {COUNTRY_CODES.map((cc, index) => (
                                <option key={index} value={cc.value}>
                                  {cc.label}
                                </option>
                              ))}
                            </select>
                            <input
                              type="text"
                              className="form-control"
                              maxLength="11"
                              //defaultValue={driverProfile.phone}
                              {...register("phone", {
                                required: {
                                  value: true,
                                  message: REQUIRED_ERR_MSG,
                                },
                                pattern: {
                                  value: PHONE_NUM,
                                  message: PATTERN_ERR_MSG,
                                },
                              })}
                            />
                            {errors.phone && (
                              <div>
                                <span className="badge bg-danger">
                                  &#9888; {errors.phone.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            State <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            defaultValue={driverProfile.state}
                            {...register("state")}
                            maxlength="50"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            City <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            defaultValue={driverProfile.city}
                            {...register("city")}
                            maxlength="50"
                            disabled
                          />
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Postal Code{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="postCode"
                            defaultValue={driverProfile.postCode}
                            {...register("postCode", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: ALPHANUM,
                                message: PATTERN_ERR_MSG,
                              },
                            })}
                            maxlength="50"
                            disabled
                          />
                          {errors.postCode && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.postCode.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-4 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Driver IC{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={driverProfile.ic}
                            {...register("ic", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxlength="200"
                          />
                          {errors.ic && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.ic.message}
                              </span>
                            </div>
                          )}
                        </div>
                        {userInfos.map((item, index) => (
                          <>
                            {userInfos[index].infoName === "USER_IC" && (
                              <>
                                <input
                                  type="hidden"
                                  {...register(
                                    `userInfoList.${index}.infoCode`
                                  )}
                                />
                                <div className="col-md-4 mb-2 position-relative">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-label"
                                  >
                                    IC Document
                                  </label>
                                  <div
                                    className="Upload-Photo w-100 mb-2"
                                    {...register(
                                      `userInfoList.${index}.infoAttachment`,
                                      {
                                        validate: (v) =>
                                          v.photoBucketUrl !== null &&
                                          v.photoBucketUrl !== "",
                                      }
                                    )}
                                  >
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        selectFile(
                                          `userInfoList.${index}.infoAttachment`,
                                          e
                                        )
                                      }
                                    />
                                    <div className="uploaded-text ms-2">
                                      {GetSvgUpload({ required: false })}
                                      {watch(
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                      ) === null ||
                                      watch(
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                      ) === ""
                                        ? "Upload License Image"
                                        : getFileName(
                                            watch(
                                              `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                    disabled={
                                      isDisabled[
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl` +
                                          "attachment"
                                      ]
                                    }
                                    onClick={() =>
                                      download(
                                        getFileName(
                                          watch(
                                            `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                          )
                                        )
                                      )
                                    }
                                  >
                                    {GetSvgIcon("IconFiledownload")}
                                  </button>
                                  {errors.userInfoList &&
                                    errors.userInfoList[index]
                                      .infoAttachment && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; This field is mandatory
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </>
                        ))}
                        {/* </div> */}
                        <div className="clearfix"> </div>
                        {/* Driver License Details */}
                        {userInfos.map((item, index) => (
                          <>
                            {userInfos[index].infoName === "DRIVER_LICENSE" && (
                              <>
                                <input
                                  type="hidden"
                                  {...register(
                                    `userInfoList.${index}.infoCode`
                                  )}
                                />
                                <div className="col-md-4 mb-2">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-label"
                                    title="This field is mandatory"
                                  >
                                    License Number{" "}
                                    <span className="text-danger">&#9733;</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register(
                                      `userInfoList.${index}.infoValue`,
                                      {
                                        required: {
                                          value: true,
                                          message: REQUIRED_ERR_MSG,
                                        },
                                        pattern: {
                                          value: ALPHANUM_NAME,
                                          message: PATTERN_ERR_MSG,
                                        },
                                      }
                                    )}
                                    maxlength="20"
                                  />
                                  {errors.infoValue && (
                                    <div>
                                      <span className="badge bg-danger">
                                        &#9888; {errors.infoValue.message}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4 mb-2 position-relative">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-label"
                                  >
                                    License
                                  </label>
                                  <div
                                    className="Upload-Photo w-100 mb-2"
                                    {...register(
                                      `userInfoList.${index}.infoAttachment`,
                                      {
                                        validate: (v) =>
                                          v.photoBucketUrl !== null &&
                                          v.photoBucketUrl !== "",
                                      }
                                    )}
                                  >
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        selectFile(
                                          `userInfoList.${index}.infoAttachment`,
                                          e
                                        )
                                      }
                                    />
                                    <div className="uploaded-text ms-2">
                                      {GetSvgUpload({ required: false })}
                                      {watch(
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                      ) === null ||
                                      watch(
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                      ) === ""
                                        ? "Upload License Image"
                                        : getFileName(
                                            watch(
                                              `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                    disabled={
                                      isDisabled[
                                        `userInfoList.${index}.infoAttachment.photoBucketUrl` +
                                          "attachment"
                                      ]
                                    }
                                    onClick={() =>
                                      download(
                                        getFileName(
                                          watch(
                                            `userInfoList.${index}.infoAttachment.photoBucketUrl`
                                          )
                                        )
                                      )
                                    }
                                  >
                                    {GetSvgIcon("IconFiledownload")}
                                  </button>
                                  {errors.userInfoList &&
                                    errors.userInfoList[index]
                                      .infoAttachment && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; This field is mandatory
                                        </span>
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-4 mb-2">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-label"
                                    title="This field is mandatory"
                                  >
                                    License Expiry Date{" "}
                                    <span className="text-danger">&#9733;</span>
                                  </label>
                                  <div>
                                    <Controller
                                      control={control}
                                      name={`userInfoList[${index}].infoExpiry`}
                                      defaultValue={item.infoExpiry}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: REQUIRED_ERR_MSG,
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => {
                                        return (
                                          <ReactDatePicker
                                            placeholderText="License Expiry Date"
                                            className="form-control"
                                            dateFormat={DATE_FORMAT_CLIENT}
                                            selected={
                                              value ? new Date(value) : value
                                            }
                                            minDate={new Date()}
                                            onChange={(d) =>
                                              onChange(
                                                d !== null
                                                  ? moment(d).format(
                                                      "YYYY-MM-DD"
                                                    )
                                                  : ""
                                              )
                                            }
                                          />
                                        );
                                      }}
                                    />
                                  </div>
                                  {/* Driver License Details End*/}
                                </div>
                              </>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3">
                    <div
                      className="tab-pane d-block border border-2 p-2"
                      id="nav-Finance"
                      role="tabpanel"
                      aria-labelledby="nav-Finance-tab"
                    >
                      <h5 className="theme-violet-txt">Bank Details</h5>
                      <div className="row">
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            data-toggle="tooltip"
                            data-placement="right"
                            title="This field is mandatory"
                          >
                            Bank Name{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bankName"
                            defaultValue={driverProfile.bankName}
                            {...register("bankName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxLength="200"
                          />
                          {errors.bankName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Beneficiary name{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="beneficiaryName"
                            defaultValue={driverProfile.beneficiaryName}
                            {...register("beneficiaryName", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxLength="200"
                          />
                          {errors.beneficiaryName && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.beneficiaryName.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12 mb-2">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Bank Account{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="bankAccount"
                            defaultValue={driverProfile.bankAccount}
                            {...register("bankAccount", {
                              required: {
                                value: true,
                                message: REQUIRED_ERR_MSG,
                              },
                              pattern: {
                                value: ALPHANUM,
                                message: PATTERN_ERR_MSG,
                              },
                            })}
                            maxLength="200"
                          />
                          {errors.bankAccount && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.bankAccount.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12 mb-2 position-relative">
                          <label
                            htmlFor=""
                            className="form-label theme-label"
                            title="This field is mandatory"
                          >
                            Upload Bank Statement{" "}
                            <span className="text-danger">&#9733;</span>
                          </label>
                          <div
                            className="Upload-Photo w-100 mb-2"
                            {...register("bankDoc", {
                              validate: (v) =>
                                v.photoBucketUrl !== null &&
                                v.photoBucketUrl !== "",
                            })}
                          >
                            <input
                              type="file"
                              onChange={(e) => selectFile("bankDoc", e)}
                            />
                            <div className="uploaded-text ms-2">
                              {GetSvgUpload({ required: false })}
                              {watch(
                                "bankDoc.photoBucketUrl",
                                "Upload Bank Statement"
                              ) === null
                                ? "Upload Bank Statement"
                                : getFileName(
                                    watch(
                                      "bankDoc.photoBucketUrl",
                                      "Upload Bank Statement"
                                    )
                                  )}
                            </div>
                          </div>
                          <button
                            type="button"
                            className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                            disabled={
                              isDisabled[
                                `driverProfile.bankDoc.photoBucketUrl` +
                                  "attachment"
                              ]
                            }
                            onClick={() =>
                              download(
                                getFileName(watch("bankDoc.photoBucketUrl"))
                              )
                            }
                          >
                            {GetSvgIcon("IconFiledownload")}
                          </button>
                          {errors.bankDoc && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; This field is mandatory
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                  {/* Truck Details start */}

                  <div className="col-md-12 mb-3">
                    <div
                      className="tab-pane d-block border border-2 p-2"
                      id="nav-Finance"
                      role="tabpanel"
                      aria-labelledby="nav-Finance-tab"
                    >
                      {vhclList.map((item, index) => (
                        <>
                          <h5 className="theme-violet-txt">Truck Details</h5>
                          <div className="row">
                            <input
                              type="hidden"
                              {...register(`vehicleList.${index}.vhclId`)}
                            />
                            <div className="col-md-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="This field is mandatory"
                              >
                                Truck Type{" "}
                                <span className="text-danger">&#9733;</span>
                              </label>
                              <Controller
                                control={control}
                                name={`vehicleList.${index}.vhclType`}
                                defaultValue={item.vhclType}
                                rules={{
                                  // required: {
                                  //   value: true,
                                  //   message: REQUIRED_ERR_MSG,
                                  // },
                                  validate: (v) => v !== 0,
                                }}
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <Select
                                      options={vhclTypes}
                                      placeholder="Select Truck Type"
                                      value={vhclTypes.find(
                                        (opt) => opt.value === value
                                      )}
                                      onChange={(val) => onChange(val.value)}
                                      className="form-control  p-0"
                                    />
                                  );
                                }}
                              />
                              {errors.vehicleList &&
                                errors.vehicleList[index] &&
                                errors.vehicleList[index].vhclType && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; This field is mandatory
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="col-md-4 mb-2">
                              <label
                                htmlFor=""
                                className="form-label theme-label"
                                title="This field is mandatory"
                              >
                                Truck Number{" "}
                                <span className="text-danger">&#9733;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                {...register(`vehicleList.${index}.vhclNo`, {
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              />
                              {errors.vehicleList &&
                                errors.vehicleList[index] &&
                                errors.vehicleList[index].vhclNo && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888;{" "}
                                      {errors.vehicleList[index].vhclNo.message}
                                    </span>
                                  </div>
                                )}
                            </div>
                            <div className="clearfix"></div>
                            {/* Vehicle Info Details start */}
                            {item.vhclInfos.map((innerItem, innerIndex) => (
                              <>
                                {(innerItem.infoCode === "ROADTAX" ||
                                  innerItem.infoCode === "PUSPAKOM" ||
                                  innerItem.infoCode === "INSURANCE") && (
                                  <>
                                    <div className="col-md-4 mb-2">
                                      <label
                                        htmlFor=""
                                        className="form-label theme-label"
                                        title="This field is mandatory"
                                      >
                                        {innerItem.infoDesc} Number{" "}
                                        <span className="text-danger">
                                          &#9733;
                                        </span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        {...register(
                                          `vehicleList.${index}.vhclInfos.${innerIndex}.infoValue`,
                                          {
                                            required: {
                                              value: true,
                                              message: REQUIRED_ERR_MSG,
                                            },
                                            pattern: {
                                              value: ALPHANUM,
                                              message: PATTERN_ERR_MSG,
                                            },
                                          }
                                        )}
                                      />
                                      {errors.vehicleList &&
                                        errors.vehicleList[index] &&
                                        errors.vehicleList[index].vhclInfos &&
                                        errors.vehicleList[index].vhclInfos[
                                          innerIndex
                                        ] &&
                                        errors.vehicleList[index].vhclInfos[
                                          innerIndex
                                        ].infoValue && (
                                          <div>
                                            <span className="badge bg-danger">
                                              &#9888;{" "}
                                              {
                                                errors.vehicleList[index]
                                                  .vhclInfos[innerIndex]
                                                  .infoValue.message
                                              }
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                    <div className="col-md-4 mb-2">
                                      <label
                                        htmlFor=""
                                        className="form-label theme-label"
                                        title="This field is mandatory"
                                      >
                                        {innerItem.infoDesc} Expiry Date{" "}
                                        <span className="text-danger">
                                          &#9733;
                                        </span>
                                      </label>
                                      <Controller
                                        control={control}
                                        name={`vehicleList.${index}.vhclInfos.${innerIndex}.expyDate`}
                                        defaultValue={item.infoExpiry}
                                        rules={{
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }}
                                        render={({
                                          field: { onChange, value },
                                        }) => {
                                          return (
                                            <ReactDatePicker
                                              placeholderText="License Expiry Date"
                                              className="form-control"
                                              dateFormat={DATE_FORMAT_CLIENT}
                                              selected={
                                                value ? new Date(value) : value
                                              }
                                              minDate={new Date()}
                                              onChange={(d) =>
                                                onChange(
                                                  d !== null
                                                    ? moment(d).format(
                                                        "YYYY-MM-DD"
                                                      )
                                                    : ""
                                                )
                                              }
                                            />
                                          );
                                        }}
                                      />
                                      {errors.vehicleList &&
                                        errors.vehicleList[index] &&
                                        errors.vehicleList[index].vhclInfos &&
                                        errors.vehicleList[index].vhclInfos[
                                          innerIndex
                                        ] &&
                                        errors.vehicleList[index].vhclInfos[
                                          innerIndex
                                        ].expyDate && (
                                          <div>
                                            <span className="badge bg-danger">
                                              &#9888;{" "}
                                              {
                                                errors.vehicleList[index]
                                                  .vhclInfos[innerIndex]
                                                  .expyDate.message
                                              }
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </>
                                )}
                                <div className="col-md-4 mb-2 position-relative">
                                  <label
                                    htmlFor=""
                                    className="form-label theme-label"
                                  >
                                    {innerItem.infoDesc} Image
                                    <span className="text-danger">&#9733;</span>
                                  </label>
                                  <div
                                    className="Upload-Photo w-100 mb-2"
                                    {...register(
                                      `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1`,
                                      {
                                        validate: (v) =>
                                          v.photoBucketUrl !== null &&
                                          v.photoBucketUrl !== "",
                                      }
                                    )}
                                  >
                                    <input
                                      type="file"
                                      onChange={(e) =>
                                        selectFile(
                                          `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1`,
                                          e
                                        )
                                      }
                                    />
                                    <div className="uploaded-text ms-2">
                                      {GetSvgUpload({ required: false })}
                                      {watch(
                                        `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                      ) === null ||
                                      watch(
                                        `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                      ) === ""
                                        ? `Upload ${innerItem.infoCode} Image`
                                        : getFileName(
                                            watch(
                                              `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                            )
                                          )}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                    disabled={
                                      isDisabled[
                                        `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl` +
                                          "attachment"
                                      ]
                                    }
                                    onClick={() =>
                                      download(
                                        getFileName(
                                          watch(
                                            `vehicleList.${index}.vhclInfos.${innerIndex}.attachment1.photoBucketUrl`
                                          )
                                        )
                                      )
                                    }
                                  >
                                    {GetSvgIcon("IconFiledownload")}
                                  </button>
                                  {errors.vehicleList &&
                                    errors.vehicleList[index] &&
                                    errors.vehicleList[index].vhclInfos[
                                      innerIndex
                                    ] &&
                                    errors.vehicleList[index].vhclInfos[
                                      innerIndex
                                    ].attachment1 && (
                                      <div>
                                        <span className="badge bg-danger">
                                          &#9888; This field is mandatory
                                        </span>
                                      </div>
                                    )}
                                </div>
                              </>
                            ))}
                          </div>
                          {/* Vehicle Info Details end */}
                          <div className="clearfix"></div>
                        </>
                      ))}
                      <div className="clearfix"></div>
                      {/* Vehicle Image */}
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Vehicle Image (Front)
                          </label>
                          <div className="input-group">
                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register(`vehiclePhotoFront`)}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectFile(`vehiclePhotoFront`, e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {vhclPhotoFPlaceHolder
                                  ? vhclPhotoFPlaceHolder
                                  : "Upload Truck Front Photo"}
                              </div>
                            </div>
                          </div>
                          {errors.vehiclePhotoFront && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.vehiclePhotoFront.message}
                              </span>
                            </div>
                          )}
                          {imgData.vhclImgF !== "" && (
                            <div className="w-100 text-center border border-1 max-img-100">
                              <img
                                src={
                                  "data:image/jpeg;base64," + imgData.vhclImgF
                                }
                                alt="img"
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Vehicle Image (Back)
                          </label>
                          <div className="input-group">
                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register(`vehiclePhotoBack`)}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectFile(`vehiclePhotoBack`, e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {vhclPhotoBPlaceHolder
                                  ? vhclPhotoBPlaceHolder
                                  : "Upload Truck Back Photo"}
                              </div>
                            </div>
                          </div>
                          {errors.vehiclePhotoBack && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.vehiclePhotoBack.message}
                              </span>
                            </div>
                          )}
                          {imgData.vhclImgB !== "" && (
                            <div className="w-100 text-center border border-1 max-img-100">
                              <img
                                src={
                                  "data:image/jpeg;base64," + imgData.vhclImgB
                                }
                                alt="img"
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Vehicle Image (Left)
                          </label>
                          <div className="input-group">
                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register(`vehiclePhotoLeft`)}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectFile(`vehiclePhotoLeft`, e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {vhclPhotoLPlaceHolder
                                  ? vhclPhotoLPlaceHolder
                                  : "Upload Truck Left Photo"}
                              </div>
                            </div>
                          </div>
                          {errors.vehiclePhotoLeft && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.vehiclePhotoLeft.message}
                              </span>
                            </div>
                          )}
                          {imgData.vhclImgL !== "" && (
                            <div className="w-100 text-center border border-1 max-img-100">
                              <img
                                src={
                                  "data:image/jpeg;base64," + imgData.vhclImgL
                                }
                                alt="img"
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Vehicle Image (Right)
                          </label>
                          <div className="input-group">
                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register(`vehiclePhotoRight`)}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectFile(`vehiclePhotoRight`, e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {vhclPhotoRPlaceHolder
                                  ? vhclPhotoRPlaceHolder
                                  : "Upload Truck Right Photo"}
                              </div>
                            </div>
                          </div>
                          {errors.vehiclePhotoRight && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.vehiclePhotoRight.message}
                              </span>
                            </div>
                          )}
                          {imgData.vhclImgR !== "" && (
                            <div className="w-100 text-center border border-1 max-img-100">
                              <img
                                src={
                                  "data:image/jpeg;base64," + imgData.vhclImgR
                                }
                                alt="img"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <label htmlFor="" className="form-label theme-label">
                            Vehicle Image (Internal)
                          </label>
                          <div className="input-group">
                            <div
                              className="Upload-Photo w-100 mb-2"
                              {...register(`vehiclePhotoInternal`)}
                            >
                              <input
                                type="file"
                                onChange={(e) =>
                                  selectFile(`vehiclePhotoInternal`, e)
                                }
                              />
                              <div className="uploaded-text ms-2">
                                {GetSvgUpload({ required: false })}
                                {vhclPhotoIPlaceHolder
                                  ? vhclPhotoIPlaceHolder
                                  : "Upload Truck Internal Photo"}
                              </div>
                            </div>
                          </div>
                          {errors.vehiclePhotoInternal && (
                            <div>
                              <span className="badge bg-danger">
                                &#9888; {errors.vehiclePhotoInternal.message}
                              </span>
                            </div>
                          )}
                          {imgData.vhclImgI !== "" && (
                            <div className="w-100 text-center border border-1 max-img-100">
                              <img
                                src={
                                  "data:image/jpeg;base64," + imgData.vhclImgI
                                }
                                alt="img"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Truck Details end */}
                  <div className="clearfix"></div>

                  <div className="w-100 text-end mt-2">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#success-modal"
                      id="modalPopup"
                      className="d-none"
                      ref={modalRef}
                    />
                    {isUserActive === "true" && (
                      <button
                        type="button"
                        className="btn btn-default border"
                        data-bs-dismiss="modal"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-outline-secondary theme-violet"
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <ApprovalLogList data={approvalHistoryData} />
      <Spinner show={show} />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
    </div>
  );
}
