import React from "react";
import { Row } from "react-bootstrap";

const VehicleDetails = ({ vhclData }) => {
  return (
    <>
      <Row>
        <div className="col grd-bg p-2 p my-2 rounded-3 ms-2">
          <div className="fw-bold">Vehicle Type</div>
          <div className="theme-blue-txt">{vhclData?.vhclTypeName}</div>
        </div>
        <div className="col grd-bg p-2 p my-2 rounded-3">
          <div className="fw-bold">Vehicle No</div>
          <div className="theme-blue-txt">{vhclData?.vhclNo}</div>
        </div>
        <div className="col grd-bg p-2 p my-2 rounded-3">
          <div className="fw-bold">Owner</div>
          <div className="theme-blue-txt">{vhclData?.vhclOwner}</div>
        </div>

        <div className="col grd-bg p-2 p my-2 rounded-3 ">
          <div className="fw-bold">Primary SBU</div>
          <div className="theme-blue-txt">{vhclData?.sbuName}</div>
        </div>
      </Row>
      <Row>
        <div className="col grd-bg p-2 p my-2 rounded-3 ms-2">
          <div className="fw-bold">Vehicle Length(ft)</div>
          <div className="theme-blue-txt">{vhclData?.vhclLength}</div>
        </div>
        <div className="col grd-bg p-2 p my-2 rounded-3">
          <div className="fw-bold">BDM(kg)</div>
          <div className="theme-blue-txt">{vhclData?.bdm}</div>
        </div>

        <div className="col grd-bg p-2 p my-2 rounded-3  ">
          <div className="fw-bold">BTM(kg)</div>
          <div className="theme-blue-txt">{vhclData?.btm}</div>
        </div>
        <div className="col grd-bg p-2 p my-2 rounded-3">
          <div className="fw-bold">Tail Gate</div>
          <div className="theme-blue-txt">
            {vhclData?.tailGate ? "Yes" : "No"}
          </div>
        </div>

        <div className="col grd-bg p-2 p my-2 rounded-3">
          <div className="fw-bold">BONDED TYPE</div>
          <div className="theme-blue-txt">{vhclData?.bondedTypeVal}</div>
        </div>
      </Row>
    </>
  );
};

export default VehicleDetails;
