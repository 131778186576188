import React, { useEffect, useState } from "react";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Layout from "../../Layout/Layout";
import { Button } from "react-bootstrap";
import GetSvgIcon from "../../../images/svglist";
import MultiRouteList from "./MultiRouteList";
import DynamicRouteAddEdit from "./DynamicRouteAddEdit";
import { SERVER_ERR_MSG } from "../../Helper/Constants";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";
import CustomToast from "../../Utilities/CustomToast";

const initToastConfig = {
  show: false,
  headerMsg: "",
  body: "",
};

const MultiRouteMain = () => {
  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const addrObj = (argStopSeq) => {
    return {
      routeId: 0,
      routeCode: "",
      stopSeq: argStopSeq,
      stopId: 0,
      city: "",
      state: "",
      postcode: "",
      address: "",
      latitude: "",
      longitude: "",
      name: "",
      phone: "",
    };
  };

  const stopsArr = [
    {
      routeId: 0,
      routeCode: "",
      stopSeq: 1,
      stopId: 0,
      city: "",
      state: "",
      postcode: "",
      address: "",
      latitude: "",
      longitude: "",
      name: "",
      phone: "",
    },
    {
      routeId: 0,
      routeCode: "",
      stopSeq: 2,
      stopId: 0,
      city: "",
      state: "",
      postcode: "",
      address: "",
      latitude: "",
      longitude: "",
      contactName: "",
      contactPhone: "",
    },
  ];

  const initRouteData = {
    id: 0,
    routeCode: "",
    origin: Object.assign({}, addrObj(0)),
    orgAddr: "",
    destination: Object.assign({}, addrObj(99)),
    destAddr: "",
    customerId: "",
    validFrom: "",
    validTo: "",
    rateType: 0,
    rateValue: 0,
    stops: stopsArr,
    remarks: "",
  };
  const initRouteModalConfig = {
    show: false,
    title: "",
  };
  const initStateCityOpt = {
    originStateArr: [],
    originCityArr: [],
    destinationStateArr: [],
    destinationCityArr: [],
    loadedStateArry: [],
    loadedCityArry: [],
  };

  const [stateCityOpt, setStateCityOpt] = useState(
    Object.assign({}, initStateCityOpt)
  );
  const [defaultStateCityOpt, setDefaultStateCityOpt] = useState(
    Object.assign({}, initStateCityOpt)
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [routeList, setRouteList] = useState([]);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [routeData, setRouteData] = useState(Object.assign({}, initRouteData));
  const [routeModalConfig, setRouteModalConfig] = useState(
    Object.assign({}, initRouteModalConfig)
  );
  const [stopLists, setStopLists] = useState([]);
  const [custList, setCustList] = useState([]);
  const [routeId, setRouteId] = useState(0);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [showCustomAlert, setShowAlert] = useState(false);
  const [geofence, setGeofence] = useState([]);
  const [chRateType, setChRateType] = useState([]);
  const [chOptValue, setChOptValue] = useState([]);
  const [toastConfig, setToastConfig] = useState(
    Object.assign({}, initToastConfig)
  );

  useEffect(() => {
    fetchRouteList();
    getDropdownData();
    fetchStatesAndCityList();
    getChOptValue();
  }, []);

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchRouteList();
  };
  const closeRouteMOdal = () => {
    setRouteData(Object.assign({}, initRouteData));
    setRouteModalConfig(Object.assign({}, initRouteModalConfig));
    setStateCityOpt(Object.assign({}, defaultStateCityOpt));
  };

  // This function will dynamically add state and city options for stops
  // As it is unknown how many stops will be there we are dynamically adding opts
  const addStateCityOpt = (noOfStops) => {
    let locStateCityOpt = { ...stateCityOpt };
    for (let index = 0; index < noOfStops; index++) {
      locStateCityOpt[`stops.${index}StateArr`] =
        locStateCityOpt.loadedStateArry;
      locStateCityOpt[`stops.${index}CityArr`] = locStateCityOpt.loadedCityArry;
    }
    setStateCityOpt({ ...locStateCityOpt });
  };

  const openRouteModal = (argData, argTitle) => {
    argData.origin =
      typeof argData?.origin === "string"
        ? JSON.parse(argData?.origin)
        : argData?.origin;
    argData.destination =
      typeof argData?.destination === "string"
        ? JSON.parse(argData?.destination)
        : argData?.destination;
    if (!!argData?.stops && argData?.stops.length > 0) {
      let tempStops =
        typeof argData?.stops === "string"
          ? JSON.parse(argData?.stops)
          : argData?.stops;
      argData.stops = !!tempStops[0] ? tempStops : [];
      addStateCityOpt(argData?.stops.length);
    } else {
      argData.stops = [];
    }

    setRouteData(Object.assign({}, argData));
    setRouteModalConfig({
      show: true,
      title: argTitle,
    });
  };
  const addRoute = (argData, argTitle) => {
    argData.rateTarget = chOptValue;
    openRouteModal(argData, argTitle);
  };

  const handleEditClick = (data) => {
    // if (!!data.ratesDropdown && typeof data.ratesDropdown === "string") {
    //   data.ratesDropdown = JSON.parse(data.ratesDropdown);
    //   setChRateType(data.ratesDropdown);
    // }
    if (!Array.isArray(data.rateTarget)) {
      data.rateTarget = !!data.rateTarget ? JSON.parse(data.rateTarget) : [];
    }
    openRouteModal(data, "EDIT ROUTE PLAN");
  };
  const handleActiveDeactive = (argRow) => {
    if (argRow.active) {
      setAlertBody(
        <>
          Are you trying to deactivate route{" "}
          <b>{!!argRow.routeCode ? argRow.routeCode : ""}</b> ?
        </>
      );
      setAlertTitle("Deactivate Route");
    } else {
      setAlertBody(
        <>
          Are you trying to activate route{" "}
          <b>{!!argRow.routeCode ? argRow.routeCode : ""}</b> ?
        </>
      );
      setAlertTitle("Activate Route");
    }
  };
  const updateCharteredRoute = async () => {
    const uri = "ActiveDeactiveCharteredRoute?id=" + routeId;
    const response = await CallApiPost(uri, {});
    handleApiResponse(response);
    toggleAlert(false);
  };
  const fetchRouteList = async () => {
    setShowSpinner(true);
    const uri = "DynamicRoutes";
    const resp = await CallApiGet(uri);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setRouteList(resp?.respData);
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to load Route List. Please try after sometime!!";
      }
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };
  const saveDynamicRoute = async (data) => {
    setShowSpinner(true);
    const uri = "SaveCharteredRoute";

    let payload = { ...data };
    const resp = await CallApiPost(uri, payload);
    handleApiResponse(resp);
    setShowSpinner(false);
  };
  const getDropdownData = async () => {
    const dropdownModel = {
      argRouteType: 1,
      argport: 1,
      argCustomer: 1,
      argChRateType: 1,
    };
    const data = await CallApiPost("GetDropdownOptions", dropdownModel);
    if (data.respCode === 200) {
      const respData = data.respData;
      setStopLists(respData.portArr);
      setCustList(respData.custArr);
      setChRateType(respData.chRateType);
    } else {
      openPopup(false, "ERROR", SERVER_ERR_MSG);
    }
  };
  const getChOptValue = async () => {
    const uri = "CharteredOptionValue";
    const resp = await CallApiGet(uri);
    if (
      resp.respCode === 200 &&
      !!resp.respData &&
      Array.isArray(resp.respData)
    ) {
      setChOptValue(resp.respData);
    }
  };
  const fetchStatesAndCityList = async () => {
    let locStateCityOpt = Object.assign({}, initStateCityOpt);
    let data = await CallApiGet("getStateAndCityList");
    if (data.respCode === 200 || data.respCode === 0) {
      setGeofence(data.respData);
      let cityObj = {};
      let stateObj = {};
      data.respData.forEach((element) => {
        if (
          !locStateCityOpt?.originCityArr.some(
            (obj) => obj.value === element.map_city
          )
        ) {
          cityObj = {
            label: element.map_city,
            value: element.map_city,
          };
          locStateCityOpt.originCityArr.push(cityObj);
          locStateCityOpt.destinationCityArr.push(cityObj);
        }
        if (
          !locStateCityOpt?.originStateArr.some(
            (obj) => obj.value === element.map_state
          )
        ) {
          stateObj = {
            label: element.map_state,
            value: element.map_state,
          };
          locStateCityOpt.originStateArr.push(stateObj);
          locStateCityOpt.destinationStateArr.push(stateObj);
        }
      });
    } else {
      handleApiResponse(data);
    }
    locStateCityOpt.loadedCityArry = locStateCityOpt.originCityArr;
    locStateCityOpt.loadedStateArry = locStateCityOpt.originStateArr;
    setStateCityOpt(locStateCityOpt);
    setDefaultStateCityOpt(Object.assign({}, locStateCityOpt));
  };
  const fetchCharteredRate = async (customerId) => {
    setShowSpinner(true);
    if (customerId === 0) {
      setChRateType([]);
    } else {
      const uri = "GetCharteredRate?customerId=" + customerId;
      const resp = await CallApiGet(uri);
      if (resp.respCode === 200) {
        setChRateType(resp.respData);
        if (
          !!resp.respData &&
          Array.isArray(resp.respData) &&
          resp.respData?.length === 0 &&
          customerId > 0
        ) {
          setToastConfig({
            show: true,
            headerMsg: "ERROR",
            body: "Rate is not defined for the selected customer ",
          });
        }
      } else {
        setToastConfig({
          show: true,
          headerMsg: "ERROR",
          body: "Rate is not defined for the selected customer ",
        });
      }
    }
    setShowSpinner(false);
  };
  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  const setCitiesForState = (key, argState) => {
    let cities = [];
    if (!!argState) {
      cities = geofence
        .filter((obj) => obj.map_state === argState)
        .map((obj) => ({ label: obj.map_city, value: obj.map_city }));
    } else {
      cities = stateCityOpt.loadedCityArry;
    }
    setStateCityOpt({ ...stateCityOpt, [key]: cities });
    // switch (key) {
    //   case "O":
    //     setStateCityOpt({ ...stateCityOpt, originCityArr: cities });
    //     break;
    //   case "D":
    //     setStateCityOpt({ ...stateCityOpt, destinationCityArr: cities });
    //     break;
    //   default:
    //     break;
    // }
  };

  const setStateForCity = (key, argCity) => {
    let states = [];
    if (!!argCity) {
      states = geofence
        .filter((obj) => obj.map_city === argCity)
        .map((obj) => ({ label: obj.map_state, value: obj.map_state }));
    } else {
      states = stateCityOpt.loadedStateArry;
    }
    setStateCityOpt({ ...stateCityOpt, [key]: states });
    // switch (key) {
    //   case "O":
    //     setStateCityOpt({ ...stateCityOpt, originStateArr: states });
    //     break;
    //   case "D":
    //     setStateCityOpt({ ...stateCityOpt, destinationStateArr: states });
    //     break;
    //   default:
    //     break;
    // }
    return states;
  };

  const closeToast = () => {
    setToastConfig(Object.assign({}, initToastConfig));
  };
  return (
    <Layout
      spinnershow={showSpinner}
      alertConfig={alertConfig}
      hideAlert={closePopup}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">Route List</div>
        </div>
        <div className="float-end d-flex align-items-center">
          <Button
            className="theme-violet"
            onClick={() => addRoute(initRouteData, "CHARTERED ROUTE")}
          >
            {GetSvgIcon("PlusCircle")} Add
          </Button>
        </div>
      </div>
      <MultiRouteList
        tableData={routeList}
        handleEditClick={handleEditClick}
        handleActiveDeactive={handleActiveDeactive}
        toggleReset={toggleAlert}
        setRouteId={setRouteId}
      />
      {showCustomAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={updateCharteredRoute}
        />
      )}
      <DynamicRouteAddEdit
        saveDynamicRoute={saveDynamicRoute}
        config={routeModalConfig}
        handleClose={closeRouteMOdal}
        routeState={routeData}
        stateCityOpt={stateCityOpt}
        stopList={stopLists}
        custList={custList}
        setCitiesForState={setCitiesForState}
        setStateForCity={setStateForCity}
        chRateType={chRateType}
        fetchCharteredRate={fetchCharteredRate}
      />
      <CustomToast
        showToast={toastConfig.show}
        closeToast={closeToast}
        headerMsg={toastConfig.headerMsg}
        body={toastConfig.body}
      />
    </Layout>
  );
};

export default MultiRouteMain;
