import React from "react";
import Modal from "react-bootstrap/Modal";

const ModalLayout = ({ children, footer, moadlConfig, handleMoalClose, header }) => {
  return (
    <>
      <Modal
        size={moadlConfig.size}
        aria-labelledby="contained-modal-title-vcenter"
        // centered
        fullscreen={!!moadlConfig?.fullscreen ? moadlConfig?.fullscreen : false}
        show={moadlConfig.show}
        onHide={handleMoalClose}
        backdrop='static'
        keyboard={false}
        dialogClassName={moadlConfig?.dialogClassName}
      >
        <Modal.Header closeButton>
          {
            !!header
              ? header
              : <Modal.Title className="section-title ">{moadlConfig.title}</Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        {
          !!footer &&
          <Modal.Footer>
            {footer}
          </Modal.Footer>
        }
      </Modal>
    </>
  );
};

export default ModalLayout;
